import React from 'react';
import '../App.css';
import '../mdb.min.css';
import { Link } from 'react-router-dom';
import $ from "jquery";
import Sidebar from './sidebar';
import { Route, Switch, Redirect } from 'react-router-dom';


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import Select from 'react-select';
import Spinner from './spiner';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import SectionSpiner from './sectionspinner';

class Inventory extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
          header:null,
          body:null,
          loads:false,
          item:null,
          Inventory_type:[],
          Inventory_year:[],
          Inventory_make:[],
          selectedYear:'All',
          selectedType:'All',
          selectedMake:'All',
          total:null,
          dealerInfo:null,
          vehicleInfo:null,
          columns:null,
        };
         this.hanleClickname = this.hanleClickname.bind(this)
         this.humanize = this.humanize.bind(this)
      }
    componentDidMount (){
 var self=this;
 var access_token=localStorage.getItem('access_token');
 var dealerid=localStorage.getItem('dealerid');
 var getStringPara={'access_token':access_token,'dealer_inventorybyid':'dealer_inventorybyid','dealer_id':dealerid};
              $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              
              var getResponse=JSON.parse(data);
              self.setState({item:getResponse});
              self.setState({dealerInfo:getResponse.dealerInfo});
              self.setState({dealerInfo_avg:getResponse.dealerInfo_avg});
              self.setState({total:getResponse.data.length})
                var leads=getResponse.data;
                const body = leads.map(function (items,index){
                  var prices= items.prices.toLocaleString('en-US') ;
                  var price =items.price.toLocaleString('en-US');
                  return {
                  Name: items.heading ? <div ><a href="javascript:(0)" onClick={(e) => { self.hanleClickname(items.mc_id)}} data-toggle="modal" data-target="#centralModalSm">{items.year+' '+items.make+' '+items.model}</a></div>:'',
                     VIN: items.vin ? items.vin :'N/A',
                    Stock: items.stock_no ? items.stock_no :'N/A',
                    PRICE: items.prices !=='' ? '$'+prices :'$'+price,
                    DOM:items.dom_active ? items.dom_active :'N/A',
                    AddedDate: items.scraped_at_date  ? items.scraped_at_date :'N/A'
                  };
                });
                self.setState({body:body})
               
                  

//Load Inventory type
 var sendData={'access_token':access_token,'dealer_inventrytype':'dealer_inventrytype','dealer_id':dealerid};

 $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              sendData
              ,
              function(data) {
                              var getResponse=JSON.parse(data);

                  self.setState({Inventory_type:getResponse.data});
              })

 //Load Inventory year
 var sendData={'access_token':access_token,'dealer_inventryyear':'dealer_inventryyear','dealer_id':dealerid};

 $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              sendData
              ,
              function(data) {
                              var getResponse=JSON.parse(data);

                  self.setState({Inventory_year:getResponse.data});
              })

  //Load Inventory make
 var sendData={'access_token':access_token,'dealer_inventrymake':'dealer_inventrymake','dealer_id':dealerid};

 $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              sendData
              ,
              function(data) {
                              var getResponse=JSON.parse(data);

                  self.setState({Inventory_make:getResponse.data});
              })


self.setState({loads:true});

              }
              );
}

handleChangeType= getValue=>{
        var self=this;
        console.log(getValue);
        self.setState({selectedType:getValue.label});
        var access_token=localStorage.getItem('access_token');
        var dealerid=localStorage.getItem('dealerid');
        var sendData={};
        
        if(self.state.selectedYear!=='All')
        {
            sendData['year']=self.state.selectedYear;
        }

        if(self.state.selectedMake!=='All')
        {
            sendData['make']=self.state.selectedMake;
        }

        if(getValue.label!=='All')
        {
            sendData['inventory_type']=getValue.label;
        }

var getStringPara={'access_token':access_token,'dealer_inventory_filter':'dealer_inventory_filter','dealer_id':dealerid,'sendData':sendData};
              $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              var getResponse=JSON.parse(data);
              self.setState({item:getResponse});
                var leads=getResponse.data;
                const body = leads.map(function (items,index){
                   var prices= items.prices.toLocaleString('en-US') ;
                  var price =items.price.toLocaleString('en-US');
                  return {
                  Name: items.heading ? <div ><a href="javascript:(0)" onClick={(e) => { self.hanleClickname(items.mc_id)}} data-toggle="modal" data-target="#centralModalSm">{items.year+' '+items.make+' '+items.model}</a></div>:'',
                     VIN: items.vin ? items.vin :'N/A',
                    Stock: items.stock_no ? items.stock_no :'N/A',
                    PRICE: items.prices !=='' ? '$'+prices :'$'+price,
                    DOM:items.dom_active ? items.dom_active :'N/A',
                    AddedDate: items.scraped_at_date  ? items.scraped_at_date :'N/A'
                  };
                });
                self.setState({body:body,loads:true})
              })
      }

handleChangeYear= getValue=>{
       var self=this;
        console.log(getValue);
       self.setState({selectedYear:getValue.label});
        var access_token=localStorage.getItem('access_token');
        var dealerid=localStorage.getItem('dealerid');
        var sendData={};
        
        if(getValue.label!=='All')
        {
            sendData['year']=getValue.label;
        }

        if(self.state.selectedMake!=='All')
        {
            sendData['make']=self.state.selectedMake;
        }

        if(self.state.selectedType!=='All')
        {
            sendData['inventory_type']=self.state.selectedType;
        }


var getStringPara={'access_token':access_token,'dealer_inventory_filter':'dealer_inventory_filter','dealer_id':dealerid,'sendData':sendData};
              $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              var getResponse=JSON.parse(data);
              self.setState({item:getResponse});
                var leads=getResponse.data;
                const body = leads.map(function (items,index){
                   var prices= items.prices.toLocaleString('en-US') ;
                  var price =items.price.toLocaleString('en-US');
                   return {
                  Name: items.heading ? <div ><a href="javascript:(0)" onClick={(e) => { self.hanleClickname(items.mc_id)}} data-toggle="modal" data-target="#centralModalSm">{items.year+' '+items.make+' '+items.model}</a></div>:'',
                     VIN: items.vin ? items.vin :'N/A',
                    Stock: items.stock_no ? items.stock_no :'N/A',
                    PRICE: items.prices !=='' ? '$'+prices :'$'+price,
                    DOM:items.dom_active ? items.dom_active :'N/A',
                    AddedDate: items.scraped_at_date  ? items.scraped_at_date :'N/A'
                  };
                });
                self.setState({body:body,loads:true})


              })
      }
handleChangeMake= getValue=>{
        var self=this;
        console.log(getValue);
       self.setState({selectedMake:getValue.label});
        var access_token=localStorage.getItem('access_token');
        var dealerid=localStorage.getItem('dealerid');
        var sendData={};
        
        if(self.state.selectedYear!=='All')
        {
            sendData['year']=self.state.selectedYear;
        }

        if(getValue.label!=='All')
        {
            sendData['make']=getValue.label;
        }

        if(self.state.selectedType!=='All')
        {
            sendData['inventory_type']=self.state.selectedType;
        }

var getStringPara={'access_token':access_token,'dealer_inventory_filter':'dealer_inventory_filter','dealer_id':dealerid,'sendData':sendData};
              $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              var getResponse=JSON.parse(data);
              self.setState({item:getResponse});
                var leads=getResponse.data;
                const body = leads.map(function (items,index){
                   var prices= items.prices.toLocaleString('en-US') ;
                  var price =items.price.toLocaleString('en-US');
                  return {
                  Name: items.heading ? <div ><a href="javascript:(0)" onClick={(e) => { self.hanleClickname(items.mc_id)}} data-toggle="modal" data-target="#centralModalSm">{items.year+' '+items.make+' '+items.model}</a></div>:'',
                     VIN: items.vin ? items.vin :'N/A',
                    Stock: items.stock_no ? items.stock_no :'N/A',
                    PRICE: items.prices !=='' ? '$'+prices :'$'+price,
                    DOM:items.dom_active ? items.dom_active :'N/A',
                    AddedDate: items.scraped_at_date  ? items.scraped_at_date :'N/A'
                  };
                });
                self.setState({body:body})
              

              })
              self.setState({loads:true})
      }
      hanleClickname=id=>{
          var self=this;
        var access_token=localStorage.getItem('access_token');
        var dealerid=localStorage.getItem('dealerid');
var getStringPara={'access_token':access_token,'dealer_inventorybymc_id':'dealer_inventorybymc_id','mc_id':id};
              $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              var getResponse=JSON.parse(data);

                self.setState({vehicleInfo:getResponse.data})
              })  

            }



        
      humanize(str) {
                var i, frags = str.split('_');
                for (i=0; i<frags.length; i++) {
                  frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
                }

   render(){
    const columns = [
  {
    name: 'Name',
    selector: 'Name',
    sortable: true,
  },
  {
    name: 'VIN',
    selector: 'VIN',
    sortable: true,
    right: true,
  },
  {
    name: 'Stock',
    selector: 'Stock',
    sortable: true,
    right: true,
  },
  {
    name: 'PRICE',
    selector: 'PRICE',
    sortable: true,
    right: true,
  },
  {
    name: 'Active Days',
    selector: 'DOM',
    sortable: true,
    right: true,
  },
  {
    name: 'Added Date',
    selector: 'AddedDate',
    sortable: true,
    right: true,
  },
];         
 var access_token= localStorage.getItem('access_token');
 
 if (access_token===null)
 {
    return <Redirect to='/'/>
  }

  

  if (!this.state.loads) {
          return <Spinner/>;
        } else {
        var info=this.state.vehicleInfo;
    return (

          <main className="pt-5 mx-lg-5">
        <div className="modal fade" id="centralModalSm" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        {/* Change class .modal-sm to change the size of the modal */}
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title w-100" id="myModalLabel">{info !== null ? info.year+' '+info.make+' '+info.model :('')}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="row">
<div className="col-lg-6">
{info !== null ? 


info.media_photos !=='' ? (<img className="border-radious card-img-top" src={info.media_photos.split(',')[0]}/>):(<img className="border-radious card-img-top" src='../media/default-car.png'/>)

:('')}

</div>
<div className="col-lg-6">
<ul className="p-1">
{this.state.vehicleInfo !== null ? 

Object.keys(this.state.vehicleInfo).map(item=>(
item!=='heading' && item!=='media_photos' && item!=='prices' && item!=='price' ? 
<li className="col-lg-12 float-left"><strong>{this.humanize(item)}</strong><span className="float-right">{this.humanize(this.state.vehicleInfo[item])!=='' ? this.humanize(this.state.vehicleInfo[item]) :('N/A')}</span></li>
:('')
))

  :('Loading...')}

</ul>


</div>


            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
        <div className="container-fluid mt-5">
<div className="card">
<div className="row">
<div className="col-lg-8">
<div className="">
<div className="card-body">

<h1 className="text-left top-0">{this.state.dealerInfo.Name!==null ? this.state.dealerInfo.Name : ('')}</h1>
<div className="row">
  <div className="col-lg-6">
    Total Inventory
<p className="text-left"><span className="fa fa-car"></span> <span>{this.state.total}</span></p>
</div>
<div className="col-lg-6">
  <span>Average days</span>
<p className="text-left"><span className="fa fa-car"></span> <span>{this.state.dealerInfo_avg.avg_days!==null ? this.state.dealerInfo_avg.avg_days : ('')}</span></p>
</div>
</div>
</div>

</div>
</div>
<div className="col-md-4" >
<div className="card-body">
<img src={this.state.dealerInfo.logo_url!==null && this.state.dealerInfo.logo_url!=='' ? this.state.dealerInfo.logo_url : ('../media/logo_default.png')} height="30px"/>
<img src="../media/SHOP_SMART_LOGO23-2-scaled.jpg" height="50px"/>

</div>
</div>
</div>


</div>

     <div className="col-md-12 mb-4">
        {/*Card*/}
        <div className="card">
          {/*Card content*/}
          <div className="card-body">
         <div className="col-lg-12">
         <div className="row">


         <div className="col-lg-3">
         <div className="form-group">
         <label className="p-0">Type</label>
          <Select name="model" defaultValue={{ label:"All", value:'All'}}
                          onChange={this.handleChangeType}
                          options={this.state.Inventory_type}/>
          </div>
          </div>

           <div className="col-lg-3">
         <div className="form-group">
         <label className="p-0">Year</label>
          <Select name="model" defaultValue={{ label:"All", value:'All'}}
                          onChange={this.handleChangeYear}
                          options={this.state.Inventory_year}/>
          </div>
          </div>

           <div className="col-lg-3">
         <div className="form-group">
         <label className="p-0">Make</label>
          <Select name="model" defaultValue={{ label:"All", value:'All'}}
                          onChange={this.handleChangeMake}
                          options={this.state.Inventory_make}/>
          </div>
          </div>
          </div>
          <a href="/">
         <img src="../media/adsbanner.jpeg" width="100%" className="blink-image" height="100%"/></a>
         </div>
            {!this.state.loads ? <SectionSpiner/> :
              
         <DataTableExtensions  columns={columns}
        data={this.state.body}>

      <DataTable
        columns={columns}
        data={this.state.body}
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        highlightOnHover
      />
    </DataTableExtensions>  }
          </div>
        </div>
        {/*/.Card*/}
      </div></div></main>

    );
  }
}
}
export default Inventory;