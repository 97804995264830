import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import $ from "jquery";
import { Route, Switch, Redirect } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Spinner from './spiner';

import Ordernow from './ordernow';
import SideBar from "./sidebar";
class autofinanceleads extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
          header:null,
          body:null,
          loads:false,
          options:null,
          total:null,
          dealerInfo:null,
          vehicleInfo:null,
          currentdealer:null,
          dealerData:null,
          status:true,

        };
                this.hanleClickname = this.hanleClickname.bind(this)

      }


    hanleClickname=(id,rowid)=>{
          var self=this;
          console.log(rowid,id);
        var access_token=localStorage.getItem('access_token');
        var dealerid=localStorage.getItem('dealerid');
        var item=self.state.item;
var getStringPara={'access_token':access_token,'dealer_inventorybyVin':'dealer_inventorybyVin','vin':id};
              $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              var getResponse=JSON.parse(data);
                self.setState({currentdealer:item[rowid]})
                self.setState({vehicleInfo:getResponse.data})
              })  

            }


        
      humanize(str) {
                var i, frags = str.split('_');
                for (i=0; i<frags.length; i++) {
                  frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
                }

    componentDidMount (){
      var self=this;
  var access_token=localStorage.getItem('access_token');
 var dealerid=localStorage.getItem('dealerid');
 var getStringPara={'dealerid':dealerid,'access_token':access_token,'auto_finance_leads':'auto_finance_leads'};
                         $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              
              var getResponse=JSON.parse(data);
               console.log(getResponse);
                self.setState({item:getResponse.data})
                self.setState({status:getResponse.status})
               
                var leads=getResponse.data !==null ? getResponse.data:[];
                const body = leads.map(function (items,index){
                   
                 return {
                    id:index,
                    CustomerEmail: items.EMAIL ? <a href={"mailto:"+items.EMAIL}>{items.EMAIL}</a> :'N/A',
                    CustomerName: items.FIRST_NAME ? items.FIRST_NAME+' '+items.LAST_NAME :'N/A',
                    CustomerPhone:items.MOBILE_PHONE ? <a href={"tel:"+items.MOBILE_PHONE}>{items.MOBILE_PHONE}</a>:'N/A',
                    Action:<div ><a href="javascript:(0)" onClick={(e) => { self.hanleClickname(items.vin,index)}} data-toggle="modal" data-target="#centralModalSm">View Leads</a></div>,
                    LeadCreadedDate: items.created_date  ? items.created_date :'N/A'
                  };
                });
                 self.setState({body:body,loads:true})
                }
              );
                         
}
   render(){
    const columns = [
  {
    name: 'Customer Name',
    selector: 'CustomerName',
    sortable: true,
  },
  {
    name: 'Customer Email',
    selector: 'CustomerEmail',
    sortable: true,
    right: true,
  },
  {
    name: 'Customer Phone',
    selector: 'CustomerPhone',
    sortable: true,
    right: true,
  },
  {
    name: 'Lead Created Date',
    selector: 'LeadCreadedDate',
    sortable: true,
    right: true,
  },
  {
    name: 'Action',
    selector: 'Action',
    sortable: true,
    right: true,
  },
];
 var access_token= localStorage.getItem('access_token');
 if (access_token==null)
 {
   
    return <Redirect to='/'  />
  }else if (!this.state.status) {
    localStorage.removeItem('access_token');
  return <Redirect to='/'  />
  }
   if(!this.state.loads)
   {

    return <Spinner/>;
   }else   {
    var info=this.state.vehicleInfo;
    console.log(this.state.vehicleInfo);
    return (<div><SideBar/>
           <main className=" main pt-5 mx-lg-5">
        <div className="container-fluid mt-5">
         <br/>
    <Ordernow/>
<div className="modal fade" id="centralModalSm" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        {/* Change class .modal-sm to change the size of the modal */}
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
             <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="row">

<div className="col-lg-12">

<h5><span className="fa fa-user"></span> Customer Information</h5>
<hr/>
{this.state.currentdealer == null ? <i>Customer Information not available...</i>:('')}
<table width="100%">
{this.state.currentdealer !== null ? 

Object.keys(this.state.currentdealer).map(item=>(
item!=='id' && item!=='DealerID' && item!=='vin' && item!=='price' && item!=='leadType'  ? 
<tr className="col-lg-12 " style={{"border-bottom":"1px solid #0000001f"}}><th>{this.humanize(item)}</th><td className="text-right">{this.state.currentdealer[item]!=='' ? this.state.currentdealer[item] :('N/A')}</td></tr>
:('')
))

  :('Loading...')}

</table>
</div>



            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

     <div className="col-md-12 mb-4">
        {/*Card*/}
       <div className="card">
            {/* Table  */}
            <DataTableExtensions  columns={columns}
        data={this.state.body}>

      <DataTable
        columns={columns}
        data={this.state.body}
        noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        highlightOnHover
      />
    </DataTableExtensions> 
            {/* Table  */}
          </div>

        {/*/.Card*/}
      </div></div></main></div>

    );
  }
}
}
export default autofinanceleads;