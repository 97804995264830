import React from 'react';
import '../App.css';
import { Link ,Route, Switch, Redirect} from 'react-router-dom';
import $ from "jquery";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Spinner from './spiner';
import Ordernow from './ordernow';
class testdriveleads extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
          header:null,
          body:null,
          loads:false,
          options:null,
          total:null,
          dealerInfo:null,
          vehicleInfo:null,
          currentdealer:null,

        };

        this.hanleClickname = this.hanleClickname.bind(this)
      }


      hanleClickname=(id,rowid)=>{
          var self=this;
          console.log(rowid,id);
        var access_token=localStorage.getItem('access_token');
        var dealerid=localStorage.getItem('dealerid');
        var item=self.state.item;
var getStringPara={'access_token':access_token,'dealer_inventorybyVin':'dealer_inventorybyVin','vin':id};
              $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              var getResponse=JSON.parse(data);
                self.setState({currentdealer:item[rowid]})
                self.setState({vehicleInfo:getResponse.data})
              })  

            }
      humanize(str) {
                var i, frags = str.split('_');
                for (i=0; i<frags.length; i++) {
                  frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
                }

    componentDidMount (){
      var self=this;
  var access_token=localStorage.getItem('access_token');
 var dealerid=localStorage.getItem('dealerid');
 var getStringPara={'access_token':access_token,'fetch_testdriveleads':'fetch_testdriveleads','dealer_id':dealerid};
                         $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              
              var getResponse=JSON.parse(data);
                self.setState({item:getResponse.data})

                var leads=getResponse.data !==null ? getResponse.data:[];
                const body = leads.map(function (items,index){
                   
                  return {
                    id:index,
                    CustomerEmail: items.email ? items.email :'N/A',
                    CustomerName: items.firstname ? items.firstname+' '+items.lastname :'N/A',
                    CustomerPhone:items.phonenumber ? items.phonenumber :'N/A',
                    testdrivedate:items.testdrivedate ? items.testdrivedate :'N/A',
                    testdrivetime:items.testdrivetime ? items.testdrivetime :'N/A',
                    comment:items.comment ? items.comment :'N/A',
                    LeadCreadedDate: items.created_date  ? items.created_date :'N/A'
                  };
                });

                self.setState({body:body,loads:true})
            
                }
              );
                         
}

   render(){
 var access_token= localStorage.getItem('access_token');
 if (access_token==null)
 {
    return <Redirect to='/'  />
  }

const data = [{ id: 1, title: 'Conan the Barbarian', year: '1982' }];
const columns = [
  {
    name: 'Customer Name',
    selector: 'CustomerName',
    sortable: true,
  },
  {
    name: 'Customer Email',
    selector: 'CustomerEmail',
    sortable: true,
  },
  {
    name: 'Customer Phone',
    selector: 'CustomerPhone',
    sortable: true,
  },
  {
    name: 'Test Drive Date',
    selector: 'testdrivedate',
    sortable: true,
  },
  {
    name: 'Test Drive Time',
    selector: 'testdrivetime',
    sortable: true,
  },
  {
    name: 'Test Message from User',
    selector: 'comment',
    sortable: true,
  },
  {
    name: 'Lead Created Date',
    selector: 'LeadCreadedDate',
    sortable: true,
  },
];
   if(!this.state.loads)
   {

    return <Spinner/>;
   }else   {
    var info=this.state.vehicleInfo;
    return (
          <main className="pt-5 mx-lg-5">
      
        <div className="container-fluid mt-5">
 <br/>
    <Ordernow/>
<div className="modal fade" id="centralModalSm" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        {/* Change class .modal-sm to change the size of the modal */}
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title w-100" id="myModalLabel">{info !== null &&  info.year !==undefined &&  info.make !==undefined &&  info.model !==undefined ? info.year+' '+info.make+' '+info.model :('')}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="row">
<div className="col-lg-6">
<h5><span className="fa fa-car"></span> Vehicle Information</h5>
<hr/>
<ul>
{this.state.vehicleInfo == '' ? (<div><i>Vehicle Information not available</i>
  <br/><small>Vehicle sold or not available</small></div>):('')}
{this.state.vehicleInfo !== null ? 

Object.keys(this.state.vehicleInfo).map(item=>(
item!=='heading' && item!=='media_photos' && item!=='prices' && item!=='price' ? 
<li className="col-lg-12 float-left"><strong>{this.humanize(item)}</strong><span className="float-right">{this.humanize(this.state.vehicleInfo[item])!=='' ? this.humanize(this.state.vehicleInfo[item]) :('N/A')}</span></li>
:('')
))

  :('Loading...')}

</ul>


</div>
<div className="col-lg-6">

<h5><span className="fa fa-user"></span> Customer Information</h5>
<hr/>
<ul>
{this.state.currentdealer !== null ? 

Object.keys(this.state.currentdealer).map(item=>(
item!=='id' && item!=='DealerID' && item!=='leadType' && item!=='price' && item!=='created_date' && item!=='vin'  ? 
<li className="col-lg-12 float-left"><strong>{item.replace(/([A-Z])/g, ' $1').trim()}</strong><span className="float-right">{this.state.currentdealer[item]!=='' ? this.state.currentdealer[item] :('N/A')}</span></li>
:('')
))

  :('Loading...')}

</ul>


</div>


            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary btn-sm" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

     <div className="col-md-12 mb-4">
        {/*Card*/}
       <div className="card">
            {/* Table  */}
                 <DataTableExtensions  columns={columns}
        data={this.state.body}>

      <DataTable
        columns={columns}
        data={this.state.body}
        noHeader
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        highlightOnHover
      />
    </DataTableExtensions> {/* Table  */}
          </div>

        {/*/.Card*/}
      </div></div></main>

    );
  }
}
}
export default testdriveleads;