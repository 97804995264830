import React from 'react';
import logo from './logo.svg';
import './App.css';

 // Import a component from another file
import Routes from './routes/Routes'; // Import a component from another file

function App() {
  return (
    <div>
        <Routes/>
    </div>
  );
}

export default App;
