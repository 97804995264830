import React from 'react';
import $ from "jquery";
import '../App.css';
import { Link } from 'react-router-dom';

import Spinner from './spiner';
import Map from './map';
import RUG from 'react-upload-gallery'
import {GoogleApiWrapper} from 'google-maps-react';

import 'react-upload-gallery/dist/style.css'
class allads extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
         item:null,
          isLoaded: true,
          adresss:null,
          phonenumer:null,
          email:null,
          usernameid:null,
          adresss:null,
          access_token:null,
          message:null,
          appendDivd:[],
          vehicleName:'New 2020 JeepCompass LATITUDE 4X4 Sport Utility',
          Milage:1000,
          images:[],
          PriceVehicle:null,
          inventorytypevalue:null,
          DealerName:null,
          DealerLongitude:null,
          DealerLatitude:null,
          Dealercountry:null,
          Dealerstate:null,
          Dealercity:null,
          Dealerwebsite:null,

        };
        this.humanize = this.humanize.bind(this)
        this.RemoveInput = this.RemoveInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClickNext = this.handleClickNext.bind(this)

      }

            handlePhoneNumber= GetNumber=>{
                      var self=this;
                        GetNumber.persist();
                      self.setState({phonenumer:GetNumber.target.value});
                      }
                   humanize=str=> {
                        var i, frags = str.split('_');
                        for (i=0; i<frags.length; i++) {
                      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                        }
                           return frags.join(' ');
                     }
                    
            handleEmail= GetEmail=>{
                      var self=this;
                      GetEmail.persist();
                      self.setState({email:GetEmail.target.value});

                    }


                     handleDealerName= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({DealerName:GetName.target.value});

                    }
                      handlemogitude= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({DealerLongitude:GetName.target.value});

                    }
                    handlelatitude= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({DealerLatitude:GetName.target.value});

                    }
                     handleZipcode= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({Dealerzipcode:GetName.target.value});

                    }
                    handleCountry= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({Dealercountry:GetName.target.value});

                    }

                    handleState= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({Dealerstate:GetName.target.value});

                    } 
                     handleCity= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({Dealercity:GetName.target.value});

                    }

                     handleWebsite= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({Dealerwebsite:GetName.target.value});

                    } 


             handleUsername= GetPassword=>{
                      var self=this;
                      GetPassword.persist();
                      self.setState({usernameid:GetPassword.target.value});

                    }
            handleAddress= handleAddress=>{
                      var self=this;
                      handleAddress.persist();
                      self.setState({adresss:handleAddress.target.value});

                    }
                    addMore=inpu=>
                    {
                      var self=this;
                      var getArr=self.state.appendDivd;
                      getArr.push(1);
                     self.setState({appendDivd:getArr});
                      
                    }

                  RemoveInput=inpus=>
                    {
                      var self=this;
                        var arrget=self.state.appendDivd;
                      arrget.splice(inpus, 1);
                      self.setState({appendDivd:arrget})
                    }

                    handleNameVehicle=name=>
                    {
                      var self=this;
                     name.persist();
                      self.setState({vehicleName:name.target.value});
                    }

                     handleMilage=value=>
                    {
                      var self=this;
                     value.persist();
                      self.setState({Milage:value.target.value});
                    }
                     handlePrice=value=>
                    {
                      var self=this;
                     value.persist();
                      self.setState({PriceVehicle:value.target.value});
                    } 

                     inventorytype=value=>
                    {
                      var self=this;
                        value.persist();
                      self.setState({inventorytypevalue:value.target.value});
                    }

                    RemoveInputs=inpus=>
                    {
                      $('#id'+inpus).remove();
                      
                    }
                    handleVIN=handleVIN=>{
                       var self=this;
                      handleVIN.persist();
                    var getVin=handleVIN.target.value;
                    var getStringPara={};
                      if(getVin.length<=17)
                      {
                        self.setState({vin:getVin});
                        getStringPara['vinsearch']=getVin;
                        getStringPara['getvindata']='getvindata';
                         $.post(
                                "https://www.shopsmartautos.com/ads_user.php",
                                  getStringPara,
                                    function(data) {
                                    var getResponse=JSON.parse(data);
                                    self.setState({item:getResponse.data});

                                    if(getResponse.status==true)
                                      {

                                    delete getResponse.data.is_valid;
                                    delete getResponse.data.decode_mode;
                                    delete getResponse.data.status;
                                    delete getResponse.data.message;

                                    self.setState({keys:Object.keys(getResponse.data)});
                                    self.setState({values:Object.values(getResponse.data)});
                                    
                                   
                                        self.setState({message:'<span class="fa fa-check" style="display:inline" role="status" aria-hidden="true"></span><p style="color:green;display:inline;">'+getResponse.message+'</p>'});
                                      }else
                                      {
                                          self.setState({message:'<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span><p style="color:red">'+getResponse.message+'</p>'});
                                      }



                                    self.setState({isLoaded:true});

                              }
                            )
                      }else
                      {
                        console.log('sdasdasda');
                      }
                        
                      }
                     
           
                      handleVehicleProperty=getprop=>{
                        var self=this;
                        var getvData=self.state.item;
                        getprop.persist();
                       var getprops= getprop.target.getAttribute('property')
                       getvData[getprops]=getprop.target.value;
                        
                        self.setState({item:getvData})
                        
                        self.setState({keys:Object.keys(getvData)});
                        self.setState({values:Object.values(getvData)});
                                    

                      }
  // receives array of files that are done uploading when submit button is clicked
            handleSubmit = (files, allFiles) => {
            console.log(files.map(f => f.meta))
            allFiles.forEach(f => f.remove())
          }
            onConfirmDelete = (currentImage, images) => {
              $('#frameModalTopInfoDemo').show();
              $('#frameModalTopInfoDemo').toggleClass('show');
          }
          handleClickNext(id)
          {
            $('.'+id).click();
            $('a.btn.btn-blue-grey.btn-circle-2.waves-effect').removeClass('active');
            $('.'+id).addClass('active');
        }

componentDidMount(){
          var navListItems = $('div.setup-panel-2 div a'),
          allWells = $('.setup-content-2'),
          allNextBtn = $('.nextBtn-2'),
          allPrevBtn = $('.prevBtn-2');
          allWells.hide();
          navListItems.click(function (e) {
          e.preventDefault();
          var $target = $($(this).attr('href')),
              $item = $(this);

      if (!$item.hasClass('disabled')) {
          navListItems.removeClass('btn-amber').addClass('btn-blue-grey');
          $item.addClass('btn-amber');
          allWells.hide();
          $target.show();
          $target.find('input:eq(0)').focus();
      }
  });

  allPrevBtn.click(function(){
      var curStep = $(this).closest(".setup-content-2"),
          curStepBtn = curStep.attr("id"),
          prevStepSteps = $('div.setup-panel-2 div a[href="#' + curStepBtn + '"]').parent().prev().children("a");
          prevStepSteps.removeAttr('disabled').trigger('click');
  });

  allNextBtn.click(function(){
      var curStep = $(this).closest(".setup-content-2"),
          curStepBtn = curStep.attr("id"),
          nextStepSteps = $('div.setup-panel-2 div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
          curInputs = curStep.find("input[type='text'],input[type='url']"),
          isValid = true;

      });

  $('div.setup-panel-2 div a.btn-amber').trigger('click');

      }
    



   render(){

var data=this.state.item;
var isLoaded=this.state.isLoaded;
var keys=this.state.keys;
var slicedArray=keys !=null ? keys.slice(0, 5):('');
var values=this.state.values;
var self=this;

   if (!isLoaded) {
          return <Spinner/>;
        } else {
    return (
          <main className="pt-5 mx-lg-5">
        <div className="col-lg-12 mt-5">
        {/* Card */}
        <div className="row mb-0">
                {/* First column */}
                <div className="col-md-12">
                  {/* Panel content */}
                  <div className="card-body pt-0">
                    {/* Table */}
                    <div className="table-responsive">
                      <table className="table table-hover">
                        {/* Table head */}
                        <thead className="rgba-stylish-strong white-text">
                          <tr>
                            <th>Queries</th>
                            <th>Clicks</th>
                            <th>Impressions</th>
                            <th>CTR</th>
                            <th>Position</th>
                            <th>Day</th>
                            <th>Week</th>
                            <th>Month</th>
                            <th>Local</th>
                            <th>Global</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* Table body */}
                        <tbody>
                          <tr className="none-top-border">
                            <td>bootstrap material design</td>
                            <td>1000</td>
                            <td>10000</td>
                            <td>5%</td>
                            <td>3.21</td>
                            <td>2 <span className="badge primary-color">1 <i className="fas fa-arrow-circle-up" /></span></td>
                            <td>2 <span className="badge red accent-2">1 <i className="fas fa-arrow-circle-down" /></span></td>
                            <td>2 <span className="badge grey">0 </span></td>
                            <td>200 (US)</td>
                            <td>2000</td>
                          </tr>
                          <tr>
                            <td>bootstrap material design</td>
                            <td>1000</td>
                            <td>10000</td>
                            <td>5%</td>
                            <td>3.21</td>
                            <td>2 <span className="badge primary-color">1 <i className="fas fa-arrow-circle-up" /></span></td>
                            <td>2 <span className="badge red accent-2">1 <i className="fas fa-arrow-circle-down" /></span></td>
                            <td>2 <span className="badge grey">0 </span></td>
                            <td>200 (US)</td>
                            <td>2000</td>
                          </tr>
                          <tr>
                            <td>bootstrap material design</td>
                            <td>1000</td>
                            <td>10000</td>
                            <td>5%</td>
                            <td>3.21</td>
                            <td>2 <span className="badge primary-color">1 <i className="fas fa-arrow-circle-up" /></span></td>
                            <td>2 <span className="badge red accent-2">1 <i className="fas fa-arrow-circle-down" /></span></td>
                            <td>2 <span className="badge grey">0 </span></td>
                            <td>200 (US)</td>
                            <td>2000</td>
                          </tr>
                        </tbody>
                        {/* Table body */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* Bottom Table UI */}
                    <div className="d-flex justify-content-between">
                      {/* Name */}
                      <select className="mdb-select colorful-select dropdown-primary mt-2">
                        <option value disabled>Rows number</option>
                        <option value={1} selected>5 rows</option>
                        <option value={2}>25 rows</option>
                        <option value={3}>50 rows</option>
                        <option value={4}>100 rows</option>
                      </select>
                      {/* Pagination */}
                      <nav className="my-4">
                        <ul className="pagination pagination-circle pg-blue mb-0">
                          {/* First */}
                          <li className="page-item disabled clearfix d-none d-md-block"><a className="page-link">First</a></li>
                          {/* Arrow left */}
                          <li className="page-item disabled">
                            <a className="page-link" aria-label="Previous">
                              <span aria-hidden="true">«</span>
                              <span className="sr-only">Previous</span>
                            </a>
                          </li>
                          {/* Numbers */}
                          <li className="page-item active"><a className="page-link">1</a></li>
                          <li className="page-item"><a className="page-link">2</a></li>
                          <li className="page-item"><a className="page-link">3</a></li>
                          <li className="page-item"><a className="page-link">4</a></li>
                          <li className="page-item"><a className="page-link">5</a></li>
                          {/* Arrow right */}
                          <li className="page-item">
                            <a className="page-link" aria-label="Next">
                              <span aria-hidden="true">»</span>
                              <span className="sr-only">Next</span>
                            </a>
                          </li>
                          {/* First */}
                          <li className="page-item clearfix d-none d-md-block"><a className="page-link">Last</a></li>
                        </ul>
                      </nav>
                      {/* Pagination */}
                    </div>
                    {/* Bottom Table UI */}
                  </div>
                  {/* Panel content */}
                </div>
                {/* First column */}
              </div>
        {/* Card */}
      </div></main>

    );
  }
}
}
export default allads;
