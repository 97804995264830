import React from 'react';

import $ from 'jquery';

import "../slider-animations.css";
import "../styles.css";
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';
import { Link } from 'react-router-dom';
class ordernow extends React.Component {
			constructor(props) {
		    super(props);
		  }

		 render() {
		 	 
		      return(<div className="col-lg-12"><a href="https://mnda.rbbpmarketingltd.com/" target="_blank" className="btn btn-primary btn-lg"><i className="fa fa-fire"></i>  Sign up and get more leads</a></div>)
		    }
		  }
		

export default ordernow;