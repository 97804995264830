import React from 'react';
import $ from "jquery";
import '../App.css';
import { Link } from 'react-router-dom';

import Spinner from './spiner';
import Map from './map';
import RUG from 'react-upload-gallery'
import {GoogleApiWrapper} from 'google-maps-react';
import 'bootstrap/dist/css/bootstrap.css';// main css file
import 'bootstrap-daterangepicker/daterangepicker.css'; // theme css file
import 'react-upload-gallery/dist/style.css'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CanvasJSReact from '../assets/canvasjs.react';
import {
  AreaChart, Area, ReferenceLine,XAxis, YAxis,LineChart, Line, Tooltip,BarChart, Bar, Cell, CartesianGrid, Legend,PieChart, Pie, Sector,FunnelChart, Funnel,
} from 'recharts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class reportview extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
         item:null,
          isLoaded: true
        };
        

      }

           handleEvent(event, picker) {
    console.log(picker.startDate);
  }
  handleCallback(start, end, label) {
    console.log(start, end, label);
  }
componentDidMount(){
         
      }
    



   render(){
var dataPoint;
    var total;
    const options = {
      theme: "light",
      animationEnabled: true,
      title:{
        text: ""
      },
      data: [{
        type: "funnel",
        indexLabel: "{label} - {y}",
        toolTipContent: "<b>{label}</b>: {y} <b>({percentage}%)</b>",
        neckWidth: 20,
        neckHeight: 0,
        valueRepresents: "area",
        dataPoints: [
          { y: 265, label: "Applications" },
          { y: 134, label: "Interviewed" },
          { y: 48, label: "Assessment" },
          { y: 26, label: "Hired" }
        ]
      }]
    }
    //calculate percentage
    dataPoint = options.data[0].dataPoints;
    total = dataPoint[0].y;
    for(var i = 0; i < dataPoint.length; i++) {
      if(i === 0) {
        options.data[0].dataPoints[i].percentage = 100;
      } else {
        options.data[0].dataPoints[i].percentage = ((dataPoint[i].y / total) * 100).toFixed(2);
      }
    }
var isLoaded=this.state.isLoaded;

const data = [
  {
    name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
  },
];

/* pie chart*/
const pie = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const data_singlePie = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const data3 = [
  {
    name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
  },
];

   if (!isLoaded) {
          return <Spinner/>;
        } else {
    return (
          <main className="pt-5 mx-lg-5">
        <div className="col-lg-12 mt-5">
        {/* Card */}

      {/*Marketing & Sales Funnel Matrics start*/}
             <div className="card text-center">
                <div className="card-body">
                  <h2>Automotive Executive Summary Report for Ideal Motors</h2>
                    <h4>Month: May 2020</h4>
                </div>
             </div>
             <div className="card">
                 
                  <header className="rgba-stylish-strong text-white card-body p-3">
                    <div className="row">
                        <div className="col-lg-6 text-left">
                        <span><i className="fa fa-line-chart" aria-hidden="true"></i> Marketing & Sales Funnel Matrics</span>
                        </div>
                        <div className="col-lg-6 text-right">
                        <span>April 1, 2020 - April 30, 2020</span>
                        </div>
                    </div>
                  </header>
                 
                  <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4">
                       <div className="card">
      <CanvasJSChart options = {options} 
         

      />
      {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
    </div>
                        </div>
                        <div className="col-lg-3">
                        <div className="card">
                              <div className="card-body">
                                  <span>Click thru Rate  </span>
                                    <div className="text-center"><h1>4.16%</h1></div>
                              </div>
                        </div>
                        <div className="card">
                              <div className="card-body">
                                  <span>Conv. Rate  </span>
                                    <div className="text-center"><h1>58.38%</h1></div>
                              </div>
                        </div>
                        <div className="card">
                              <div className="card-body">
                                  <span>Impression per Conv.</span>
                                    <div className="text-center"><h1>21.51%</h1></div>
                              </div>
                        </div>
                        </div>
                        <div className="col-lg-4">

                         <div className="card">
                              <div className="card-body">
                                  <span>Cost  per Visitor</span>
                                    <div className="text-center"><h1>$0.58</h1></div>
                              </div>
                        </div>
                        <div className="card">
                              <div className="card-body">
                                  <span>Cost per Conv.</span>
                                    <div className="text-center"><h1>$1.00</h1></div>
                              </div>
                        </div>
                        <div className="card">
                              <div className="card-body">
                                  <span>Cost</span>
                                    <div className="text-center"><h1>$7,028</h1></div>
                              </div>
                        </div>
                        <div className="card">
                              <div className="card-body">
                                  <span>Form Submission</span>
                                    <div className="text-center"><h1>113</h1></div>
                              </div>
                        </div>
                        </div>
                    </div>
                  </div>
                {/*Marketing & Sales Funnel Matrics end*/}


             </div>
             {/*Vehicle inventory Summary start*/}
             <div className="card">
                 
                  <header className="rgba-stylish-strong text-white card-body p-3">
                    <div className="row">
                        <div className="col-lg-6 text-left">
                        <span><i className="fa fa-car" aria-hidden="true"></i> Vehicle inventory Summary</span>
                        </div>
                        <div className="col-lg-6 text-right">
                        <span>April 1, 2020 - April 30, 2020</span>
                        </div>
                    </div>
                  </header>
                 
                  <div className="card-body">
                  <p>SnapShot of Vehicle inventory on dealer's lot</p>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                             <div className="row card-body">
                                <div className="col-lg-6">
                                    <span>Total Vehicles</span>
                                    <h2>695</h2>
                                </div>
                                <div className="col-lg-6 text-center">
                                    <span className="fa fa-car caricon"></span>
                                </div>
                              </div>
                            </div>
                              <div className="card">
                             <div className="row card-body">
                                <div className="col-lg-6 text-center">
                                    <span>New Vehicles</span>
                                    <h2>464</h2>
                                </div>
                                <div className="col-lg-6 text-center">
                                   <span>Used Vehicles</span>
                                    <h2>231</h2>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                              <div className="card">
                              <AreaChart width={500} height={400} data={data} margin={{top: 10, right: 30, left: 0, bottom: 0,}}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
                              </AreaChart>
                        </div>
                        </div>
                        <div className="col-lg-2">

                         <div className="card">
                              <div className="card-body">
                                  <span>Average Age</span>
                                    <div className="text-center"><h1>83</h1></div>
                              </div>
                        </div>
                        <div className="card">
                              <div className="card-body">
                                  <span># Vehicles Past 28 Days</span>
                                    <div className="text-center"><h1>426</h1></div>
                              </div>
                        </div>
                        </div>
                    </div>
                  </div>
                {/*Vehicle inventory Summary end*/}
                
             </div>

              {/*Conquest email insight start*/}
             <div className="card">
                 
                  <header className="rgba-stylish-strong text-white card-body p-3">
                    <div className="row">
                        <div className="col-lg-6 text-left">
                        <span><i className="fa fa-cloud" aria-hidden="true"></i> Conquest Email Insight</span>
                        </div>
                        <div className="col-lg-6 text-right">
                        <span>April 1, 2020 - April 30, 2020</span>
                        </div>
                    </div>
                  </header>
                 
                  <div className="card-body">
                  <p>Emails sent to individuals who expressed intrests in specific vehicles</p>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card">
                             <div className="row card-body">
                                <div className="col-lg-6">
                                    <span>Open</span>
                                    <h2>612</h2>
                                </div>
                                <div className="col-lg-6 text-center">
                                    <span className="fa fa-envelope cariconenvolpe"></span>
                                </div>
                              </div>
                            </div>
                              <div className="card">
                             <div className="row card-body">
                                <div className="col-lg-6">
                                    <span>Clicks</span>
                                    <h2>198</h2>
                                </div>
                                <div className="col-lg-6 text-center">
                                    <div className="d-inline">
                                        <span className="fa fa-male maleicon iconactive"></span>
                                        <span className="fa fa-male maleicon"></span>
                                        <span className="fa fa-male maleicon"></span>
                                    </div><br/>
                                    <div className="d-inline">
                                        <span className="fa fa-male maleicon"></span>
                                        <span className="fa fa-male maleicon"></span>
                                        <span className="fa fa-male maleicon"></span>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card">
                              <div className="card-body text-center">
                                  <span>Deployed  </span>
                                    <div className="text-center"><span className="fa fa-paper-plane caricon"></span></div>
                                    <div className="text-center"><h1>5,000</h1></div>
                              </div>
                          </div>
                        
                        </div>
                        <div className="col-lg-3">
                         <div className="card">
                              <div className="card-body text-center">
                                  <span>Open Rate  </span>
                                    <div className="text-center"><span className="fa fa-television caricon"></span></div>
                                    <div className="text-center"><h1>12.24%</h1></div>
                              </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                         <div className="card">
                              <div className="card-body text-center">
                                  <span>Click Rate  </span>
                                    <div className="text-center"><span className="fa fa-hand-o-up caricon"></span></div>
                                    <div className="text-center"><h1>3.96%</h1></div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                {/*Conquest email insight end*/}
                
             </div>


              {/*Geofencing compaign performance start*/}
             <div className="card">
                 
                  <header className="rgba-stylish-strong text-white card-body p-3">
                    <div className="row">
                        <div className="col-lg-6 text-left">
                        <span><i className="fa fa-globe" aria-hidden="true"></i> Geofencing Compaign Performance</span>
                        </div>
                        <div className="col-lg-6 text-right">
                        <span>April 1, 2020 - April 30, 2020</span>
                        </div>
                    </div>
                  </header>
                 
                  <div className="card-body">
                  <p>Hyper-local targeted visitors (100% mobile delivery), based on specific competative targets</p>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card">
                             <div className="row card-body">
                                <div className="col-lg-12">
                                    <span>Impressions</span>
                                    <h2>167,289</h2>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card">
                              <div className="card-body">
                                  <span>Clicks  </span>
                                    <div className="text-center"><h2>141</h2></div>
                              </div>
                          </div>
                        
                        </div>
                        <div className="col-lg-3">
                          <div className="card">
                              <div className="card-body">
                                  <span>Conversions  </span>
                                    <div className="text-center"><h2>3,750</h2></div>
                              </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="card">
                              <div className="card-body">
                                  <span>CTR  </span>
                                    <div className="text-center"><h2>0.08%</h2></div>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div className="col-lg-12">

      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="pv" stackId="a" fill="#8884d8" />
        <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
      </BarChart>

                    </div>
                  </div>
                {/*Vehicle inventory Summary end*/}
                
             </div>


              {/*Users by channel start*/}
             <div className="card">
                 
                  <header className="rgba-stylish-strong text-white card-body p-3">
                    <div className="row">
                        <div className="col-lg-6 text-left">
                        <span><i className="fa fa-signal" aria-hidden="true"></i> Users By Channel</span>
                        </div>
                        <div className="col-lg-6 text-right">
                        <span>April 1, 2020 - April 30, 2020</span>
                        </div>
                    </div>
                  </header>
                 
                  <div className="card-body">
                  <p>The top channels sending visitors to your website</p>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                             <div className="row card-body">
                                <div className="col-lg-12">
                                    <span>Current Year</span>
                                    <PieChart width={400} height={400}>
        <Pie
          data={pie}
          cx={200}
          cy={200}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card">
                              <div className="card-body">
                              <span>Previous Year</span>
                                                       <PieChart width={400} height={400}>
        <Pie
          data={pie}
          cx={200}
          cy={200}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
                              </div>
                          </div>
                          </div>
                    </div>
                  </div>
                   </div>
                {/*Users by channel end*/}
                


              {/*Google my bussiness insight start*/}
             <div className="card">
                 
                  <header className="rgba-stylish-strong text-white card-body p-3">
                    <div className="row">
                        <div className="col-lg-6 text-left">
                        <span><i className="fa fa-google" aria-hidden="true"></i> Google My Bussiness Insight</span>
                        </div>
                        <div className="col-lg-6 text-right">
                        <span>April 1, 2020 - April 30, 2020</span>
                        </div>
                    </div>
                  </header>
                 
                  <div className="card-body">
                  
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card">
                            <div className="card-body">
                             <p>Detailed ehavior of your Google My Bussiness Funnel </p>
                             <div className=" card-body">
                                <div className="row">
                                <div className="col-lg-3 text-center pl-0 bxshado">
                                    <h5>5,539</h5>
                                     <small></small>
                                    <div className="card borderradious bg-green">
                                      <div className="card-body">Views</div>
                                    </div>
                                    <span className="fa fa-eye"></span>
                                </div>

                                <div className="col-lg-3 text-center pl-0 bxshado">
                                 <h5>5,539</h5>
                                 <small>87.6%</small>
                                   <div className="card borderradious bg-orange">
                                      
                                      <div className="card-body">Traffic</div>
                                    </div>
                                      <span className="fa fa-train"></span>
                                </div>


                                <div className="col-lg-3 text-center pl-0 bxshado">
                                 <h5>5,539</h5>
                                  <small>3.4%</small>
                                    <div className="card borderradious bg-blue">
                                      
                                      <div className="card-body">Actions</div>
                                    </div>
                                    <span className="fa fa-hand-o-up"></span>
                                </div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card">
                              <div className="card-body">
                                  
<PieChart width={400} height={400} onMouseEnter={this.onPieEnter}>
        <Pie
          data={data_singlePie}
          cx={120}
          cy={200}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>
                              </div>
                          </div>
                        
                        </div>

                          <div className="col-lg-6">
                            <div className="card">
                              <div className="card-body">
                                <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10, right: 30, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
        <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
        
      </AreaChart>

                              </div>
                            </div>
                          
                        
                        </div>
                           <div className="col-lg-6">
                            <div className="card">
                              <div className="card-body">
                                <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10, right: 30, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
        <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
        
      </AreaChart>

                              </div>
                            </div>
                          
                        
                        </div>

                    </div>
                  </div>
                {/*Google my bussiness insight end*/}
                
             </div>



              {/*Facebook insight start*/}
             <div className="card">
                 
                  <header className="rgba-stylish-strong text-white card-body p-3">
                    <div className="row">
                        <div className="col-lg-6 text-left">
                        <span><i className="fa fa-facebook-square" aria-hidden="true"></i> Facebook Insight</span>
                        </div>
                        <div className="col-lg-6 text-right">
                        <span>April 1, 2020 - April 30, 2020</span>
                        </div>
                    </div>
                  </header>
                 
                  <div className="card-body">
                  <p>Detailed ehavior of your Google My Bussiness Funnel </p>
                    <div className="row">
                          <div className="col-lg-4">
                            <div className="card">
                              <div className="card-body">
                                <AreaChart width={300} height={400} data={data} margin={{top: 10, right: 30, left: 0, bottom: 0,}}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                                <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                              </AreaChart>

                            </div>
                          </div>
                          <div className="card">
                              <div className="card-body">
                                <AreaChart width={300} height={400} data={data} margin={{top: 10, right: 30, left: 0, bottom: 0,}}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
                                <Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                              </AreaChart>

                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card">
                              <div className="card-body">
                                  <span>Sessions  </span>
                                    <div className="text-center"><h2>1,984</h2></div>
                              </div>
                          </div>
                            
                           <div className="card">
                              <div className="card-body">
                                  <span>Sessions via Social Referrel </span>
                                    <div className="text-center"><h2>387</h2></div>
                              </div>
                          </div>
                          <div className="card">
                              <div className="card-body">
                                  <span>Conversions</span>
                                    <div className="text-center"><h2>817</h2></div>
                              </div>
                          </div>
                          <div className="card">
                              <div className="card-body">
                                  <span>Contributed Social Conversions</span>
                                    <div className="text-center"><h2>30</h2></div>
                              </div>
                          </div>
                          <div className="card">
                              <div className="card-body">
                                  <span>Last interaction Social Conv.</span>
                                    <div className="text-center"><h2>24</h2></div>
                              </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card">
                             <table className="table table-striped">
                                 <tr className="theader"> <th className="">City</th>
                                  <th>Reach</th>
                                  </tr>
                                  <tr>
                                        <td>Miami,FL</td><td>7,105</td>
                                  </tr>
                                  <tr>
                                        <td>Atlana,GA</td><td>7,105</td>
                                  </tr>
                                  <tr>
                                        <td>Orlando,FL</td><td>3,958</td>
                                  </tr>
                                  <tr>
                                        <td>Charollate,FL</td><td>2,989</td>
                                  </tr>
                                  <tr>
                                        <td>Jacksonville,FL</td><td>2,042</td>
                                  </tr>
                                  <tr>
                                        <td>Tampa,FL</td><td>1,396</td>
                                  </tr>
                                  <tr>
                                        <td>Raleigh,NC</td><td>1,093</td>
                                  </tr>
                                  <tr>
                                        <td>Winston-Salem,NC</td><td>901</td>
                                  </tr>
                                  <tr>
                                        <td>Greensboro,NC</td><td>833</td>
                                  </tr>
                                  <tr>
                                        <td>Columbus,GA</td><td>692</td>
                                  </tr>
                             </table>
                          </div>
                        
                        </div>
                    </div>
                  </div>
                {/*Facebook insight insight end*/}
              
                  {/*Compaign:AIR 2004 - Offers start*/}
                  <header className="rgba-stylish-strong text-white card-body p-3">
                    <div className="row">
                        <div className="col-lg-6 text-left">
                        <span><i className="fa fa-facebook-square" aria-hidden="true"></i> Compaign:AIR 2004 - Offers</span>
                        </div>
                        <div className="col-lg-6 text-right">
                        <span>April 1, 2020 - April 30, 2020</span>
                        </div>
                    </div>
                  </header>
                 
                  <div className="card-body">
                 
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                             <div className="row card-body">
                                <div className="col-lg-12">
                                    <span>Links Clicks</span>
                                    <h2>167,289</h2>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card">
                              <div className="card-body">
                                  <span>Reach  </span>
                                    <div className="text-center"><h2>1,984</h2></div>
                              </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="card">
                              <div className="card-body">
                                  <span>Spent  </span>
                                    <div className="text-center"><h2>1,984</h2></div>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="row card-body">
                          <div className="col-lg-8">
                          <LineChart
          width={500}
          height={200}
          data={data}
          margin={{
            top: 10, right: 30, left: 0, bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line connectNulls type="monotone" dataKey="uv" stroke="#8884d8" fill="#8884d8" />
        </LineChart>




                          </div>
                          <div className="col-lg-8">
                          <BarChart width={600} height={300} data={data}
            margin={{top: 5, right: 30, left: 20, bottom: 5}}>
       <CartesianGrid strokeDasharray="3 3"/>
       <XAxis dataKey="name"/>
       <YAxis/>
       <Tooltip/>
       <Legend />
       <ReferenceLine y={1} stroke='#000'/>
       <Bar dataKey="pv" fill="#8884d8" />
       <Bar dataKey="uv" fill="#82ca9d" />
      </BarChart>
                          </div>
                          <div className="col-lg-4">er</div>
                        </div>
                    </div>
                  </div>
                    {/*Compaign:AIR 2004 - Offers*/}
             </div>
        {/* Card */}
      </div></main>

    );
  }
}
}
export default reportview;
