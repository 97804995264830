import React from 'react';
import '../App.css';

import Leads from "./leads";
import SideBar from "./sidebar";
class DealerBoard extends React.Component {
  
   render(){   
   
    return (
    	<div className="">
      <SideBar/>
     
    </div>


    );
  }
}
export default DealerBoard;