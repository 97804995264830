import React from 'react';
import { Route,BrowserRouter as Router } from "react-router-dom";
import dealerlogin from "../component/dealerlogin";                            
import registerforads from "../component/registerforads";
import userdashboard from '../component/userdashboard'; // Import a component from another file
import forgotpassword from '../component/forgotpassword'; // Import a component from another file
import UserProfile from '../component/userprofile'; // Import a component from another file
import leads from '../component/leads'; // Import a component from another file
import overview from '../component/overview'; // Import a component from another file
import adsadd from '../component/adsadd'; // Import a component from another file
import Inventory from '../component/inventory'; // Import a component from another file
import Leads from '../component/leads'; // Import a component from another file
import passwordchange from '../component/passwordchange'; // Import a component from another file
import logout from '../component/logout'; // Import a component from another file
import autopromote from '../component/autopromote'; // Import a component from another file
import allads from '../component/allads'; // Import a component from another file
import reports from '../component/reports'; // Import a component from another file
import reportview from '../component/reportview'; // Import a component from another file
import kbbleads from '../component/kbbleads'; // Import a component from another file
import testdriveleads from '../component/testdriveleads'; // Import a component from another file
import chatbotnewusedcertifedleads from '../component/chatbotnewusedcertifedleads'; // Import a component from another file
import autofinanceleads from '../component/autofinanceleads'; // Import a component from another file

class Routes extends React.Component {
  render() {

  	return(
        <div>
        
        <Router>  

        <Route exact path="/" component={dealerlogin} />
        <Route path="/dealer-dashboard/" component={userdashboard} />  
        <Route path="/register-for-ads" component={registerforads} />
        <Route path="/forgot-password" component={forgotpassword} />
        <Route path="/dealer-dashboard/user-profile" component={UserProfile} />
        <Route path="/dealer-dashboard/user-ads" component={leads} />
        <Route path="/dealer-dashboard/overview" component={overview} />
        <Route path="/dealer-dashboard/ads-add" component={adsadd} />
        <Route path="/dealer-dashboard/inventory" component={Inventory} />
        <Route path="/dealer-dashboard/password-change" component={passwordchange} />
        <Route path="/dealer-dashboard/leads" component={Leads} />
        <Route path="/dealer-dashboard/logout" component={logout} />
        <Route path="/dealer-dashboard/auto-promote" component={autopromote} />
        <Route path="/dealer-dashboard/allads" component={allads} />
        <Route path="/dealer-dashboard/reports" component={reports} />
        <Route path="/dealer-dashboard/report-view" component={reportview} />
        <Route path="/dealer-dashboard/chat-new-leads" component={chatbotnewusedcertifedleads} />
        <Route path="/dealer-dashboard/auto-finance-leads" component={autofinanceleads} />
        <Route path="/dealer-dashboard/private-seller" component={kbbleads} />
        <Route path="/dealer-dashboard/test-drive-leads" component={testdriveleads} />

        </Router>
      </div>


      );
  }}
 export default Routes;