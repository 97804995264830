import React from 'react';
import '../App.css';
import '../custom.js';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Spinner from './spiner';

class createads extends React.Component {
constructor(props) {
        super(props);
        this.state = {
          error: null,
          errors: [],
          isLoaded: true,
          items: [],
          phonenumer:null,
          dealerID:null,
          password:null,
          message:null,
          Validationpnumer:null,
          varificationcode:null,
          access_token:null,
          userid:null,
        };
        this.FormValidations = this.FormValidations.bind(this)
      }
  FormValidations=getValidate=>{
        var self=this;
        let dealerID=self.state.dealerID;
        let password=self.state.password;
        let Messages={};
        
        if(dealerID==null)
        {
          Messages['dealerID']='Please fill the dealerID';
          self.setState({errors:Messages});
          return false;
        }else if(password==null)
        {
          Messages['password']='Please fill up the Password!';
          self.setState({errors:Messages});
          return false;
        }else
        {
          self.setState({errors:''});
          return true;
        }

        
      }
        componentDidMount (){
              var self=this;
              var access_token= localStorage.getItem('access_token');
              console.log(access_token);

              if (access_token!==null) {
              window.location.href="/dealer-dashboard/inventory";
              }

            }
      handleLogin=handleRegisterGet=>{
          handleRegisterGet.persist();
          var self=this;
          let dealerID=self.state.dealerID;
          let password=self.state.password;
 self.setState({ isLoaded: true });
          if(self.FormValidations())
          {
         
          var getStringPara={'dealerID':dealerID,'password':password,'login_dealer':'login_dealer'};
              $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
               
              var getResponse=JSON.parse(data);
              if(getResponse.status==true)
              {
                    self.setState({access_token:getResponse.access_token})
                    localStorage.setItem('access_token',getResponse.access_token);
                      localStorage.setItem('dealerid',dealerID);
                self.setState({message:'<div class="alert alert-success">'+getResponse.message+'</div>'})
                  window.location.href='/dealer-dashboard/inventory';
                  
              }else
              {
                self.setState({message:'<div class="alert alert-danger">'+getResponse.message+'</div>'})
                
              }
              }
              ); 
          }

        }

        handledealerID= GetdealerID=>{
                      var self=this;
                      GetdealerID.persist();
                      self.setState({dealerID:GetdealerID.target.value});

                    }

             handlePassword= GetPassword=>{
                      var self=this;
                      GetPassword.persist();
                      self.setState({password:GetPassword.target.value});

                    }
   render(){  
  if (!this.state.isLoaded) {
          return <Spinner/>;
        } else {
    return (

      <div className="col-lg-12 backgroundImage">
         {/* Default form login */}
	<div className=" row text-center p-3">
	<div className="col-lg-8">
	<div className="content text-white mt-5">
	
     <h1 className="content text-white mt-5">Our Dealers Love Us Too!</h1>
   <p>If you haven’t registered as a ShopSmartAutos dealer, consider this. Thousands of consumers from across our Country visit us every day, looking for their next vehicle. They find the vehicle they are looking for and link directly to your dealer inventory. Our shoppers want information on a specific vehicle. Find out how we can send those VIN specific exclusive shoppers to you.</p>
		</div>
        </div>
        <div className="col-lg-4  card text-center border border-light p-3 mt-5 ">
        <p className="h4 mb-4">Sign in</p>
        {/* dealerID */}
       <input type="dealerID" name="dealerID" id="defaultLoginFormdealerID" onChange={this.handledealerID} className="form-control mb-4" placeholder="Dealer Id" />
        <p style={{color:"red"}}>{this.state.errors['dealerID']}</p>
        {/* Password */}
      <input type="password" name="password" id="defaultLoginFormPassword" onChange={this.handlePassword} className="form-control mb-4" placeholder="Password" />
        <p style={{color:"red"}}>{this.state.errors['password']}</p>
        <div className="d-flex justify-content-around">
          <div>
            {/* Remember me */}
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="defaultLoginFormRemember" />
              <label className="custom-control-label" htmlFor="defaultLoginFormRemember">Remember me</label>
            </div>
          </div>
          <div>
            {/* Forgot password */}
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
        </div>
        {/* Sign in button */}
       <div dangerouslySetInnerHTML={{ __html:this.state.message}}/>
        <button className="btn btn-primary btn-block my-4" onClick={this.handleLogin}>Sign in</button>
        {/* Register */}
        {/* Social login */}
        </div>


        </div>
        <div className=" text-center text-white"><h3 className="copyr">&copy; ShopSmartAutos 2020</h3></div>
        </div>
      

    );
  }
}
}
export default createads;