import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import $ from "jquery";
import { Route, Switch, Redirect } from 'react-router-dom';

import Spinner from './spiner';
import Facebookads from './facebookads';
import Facebook from './facebook';
import Googleads from './googleads';
class autopromote extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
          header:null,
          body:null,
          loads:false,
          options:null,
          total:null,
          dealerInfo:null,
          vehicleInfo:null,
          currentdealer:null,
          network:'facebookads',

        };
      }


      hanleClickname=(id,rowid)=>{
          var self=this;
          console.log(rowid);
        var access_token=localStorage.getItem('access_token');
        var dealerid=localStorage.getItem('dealerid');
        var item=self.state.item;
var getStringPara={'access_token':access_token,'dealer_inventorybyVin':'dealer_inventorybyVin','vin':id};
              $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              var getResponse=JSON.parse(data);
                self.setState({currentdealer:item[rowid]})
                self.setState({vehicleInfo:getResponse.data})
              })  

            }



        
      humanize(str) {
                var i, frags = str.split('_');
                for (i=0; i<frags.length; i++) {
                  frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
                }

    componentDidMount (){
      var self=this;
  var access_token=localStorage.getItem('access_token');
 var dealerid=localStorage.getItem('dealerid');
 var getStringPara={'access_token':access_token,'fetch_mc_leads':'fetch_mc_leads','dealer_id':dealerid};
                         $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              
              var getResponse=JSON.parse(data);
               console.log(getResponse);
                self.setState({item:getResponse.data})
               
                var leads=getResponse.data;
                
                self.setState({loads:true})
                }
              );
                         
}
handleNetwork=getvalue=>
{
  var self=this;
  getvalue.persist();
  self.setState({network:getvalue.target.value})
  
}

   render(){
 var access_token= localStorage.getItem('access_token');
 if (access_token==null)
 {
    return <Redirect to='/'  />
  }
   if(!this.state.loads)
   {

    return <Spinner/>;
   }else   {
    var info=this.state.vehicleInfo;
    return (
          <main className="pt-5 mx-lg-5">
          <div className="container-fluid mt-5 ">
      <div className="card">
                    <div className="row card-body">
                          <div className="col-lg-2">
                                <div className="form-group">
                                  <label className="p-0">Dealer</label>
                                    <select className="form-control">
                                          <option>All</option>
                                          <option selected>Ideal Motors</option>
                                    </select>
                                </div>
                          </div>
                          <div className="col-lg-2">
                                <div className="form-group">
                                <label className="p-0">Network</label>
                                <select className="form-control" onChange={this.handleNetwork}>
                                    <option value="facebookads">Facebook Ads</option>
                                    <option value="facebook">Facebook</option>
                                    <option value="googleads" >Google ads</option>
                                    <option value="googleads" >Instagram ads</option>
                                    <option value="googleads" >Bing ads</option>
                                </select>
                              </div>
                          </div>

                          <div className="col-lg-2">
                                <div className="form-check form-group mt-4">
                                <input className="form-check-input" type="checkbox" id="checkbox1" defaultChecked="checked" />
                                <label className="form-check-label" htmlFor="checkbox1">Auto Promote</label>
                                </div>
                          </div> 
                </div>
            </div>
            {
                this.state.network!==null && this.state.network=='facebookads'
          ?
          <Facebookads/>
          :
          this.state.network!==null && this.state.network=='facebook'
          ?
          <Facebook/>
          :
          this.state.network!==null && this.state.network=='googleads'
          ?
          <Googleads/>
          :
           'NA'
        }

</div></main>
        );
  }
}}

export default autopromote;