import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import React from 'react';
import $ from "jquery";

export class MapContainer extends  React.Component  {
  constructor(props) {
        super(props);
        this.state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    lat:null,
    long:null,
  }
      
      }



 onMapClicked = (ref, map, ev) => {
  var self= this;
   console.log(ev);
  const location = ev.latLng;
  const lng = ev.latLng.lng();
  const lat = ev.latLng.lat();
   self.setState({lat:lat,long:lng});
    $('#lati').val(lat);
    $('#longi').val(lng);
  }
  render() {
    return (
      <Map google={this.props.google} zoom={14} onClick={this.onMapClicked}  style={{width: '100%', height: '100%', position: 'relative'}}>
 
        <Marker onClick={this.onMarkerClick}
                name={'Current location'}  position={{ lat: this.state.lat, lng: this.state.long }}/>
 
        <InfoWindow onClose={this.onInfoWindowClose} marker={this.state.activeMarker}>
            
        </InfoWindow>
      </Map>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ('AIzaSyA6PdXdukxnvnSogbgRRT1B4n185wip_8Y')
})(MapContainer)