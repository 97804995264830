import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import '../mdb.min.css';

import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.js';
class SideBar extends React.Component {

 constructor(props) {
        super(props);
        
        
      }
 
 
  handleToggle= handleToggle=>{
        var self=this;
        handleToggle.persist();
        $(".wrapper").toggleClass("toggled");
 

      }

   render(){   
    return (
      <header>
        {/* Sidebar navigation */}
        <div id="slide-out" className="side-nav  fixed bg-white">
          <ul className="custom-scrollbar">
            {/* Logo */}
            <li className="logo-sn waves-effect py-5">
              <div className="text-center">
                <a href="/dealer-dashboard/inventory" className="pl-0"><img src="../media/SHOP_SMART_LOGO23-2-scaled.jpg" /></a>
              </div>
            </li>
            {/* Search Form */}
         
            {/* Side navigation links */}
             <div className="list-group list-group-flush">
        
        <Link to="/dealer-dashboard/inventory" className="list-group-item list-group-item-action waves-effect">
          <i className="fas fa-car mr-3" />Inventory</Link>

         
           <Link to="/dealer-dashboard/leads" className="list-group-item list-group-item-action waves-effect">
     
          <i className="fas fa-map mr-3" />Leads</Link>
          
          <Link to="/dealer-dashboard/auto-finance-leads" className="list-group-item list-group-item-action waves-effect">
     
     <i className="fas fa-line-chart  mr-3" />Auto Finance Leads</Link>
           <a target="_blank" href="https://visualanalytics.polk.com/" className="list-group-item list-group-item-action waves-effect">
     
          <i className="fas fa-bullhorn mr-3" />IHS Signup</a>

          <a target="_blank" href="https://visualanalytics.polk.com/" className="list-group-item list-group-item-action waves-effect">
     
          <i className="fas fa-bar-chart mr-3" />IHS Reports</a>
<Link to="/dealer-dashboard/chat-new-leads" className="list-group-item list-group-item-action waves-effect">
     
          <i className="fas fa-comments mr-3" />Chat New Leads</Link>
<Link to="/dealer-dashboard/private-seller" className="list-group-item list-group-item-action waves-effect">
     
         <span className="fa fa-user-o"></span> <span className="pl-2">Private Seller</span></Link>

<Link to="/dealer-dashboard/test-drive-leads" className="list-group-item list-group-item-action waves-effect">
     
<img src="https://www.shopsmartautos.com/wp-content/uploads/kbb/car-steering-wheel1.png" hieght="20px" width="20px"/>   <span className="pl-2">Test Drive Leads</span>
          </Link>


        </div>
            {/* Side navigation links */}
          </ul>
          <div className="sidenav-bg mask-strong" />
        </div>
        {/* Sidebar navigation */}
        {/* Navbar */}
        <nav className="navbar fixed-top navbar-expand-lg scrolling-navbar double-nav bg-white">
          {/* SideNav slide-out button */}
          <div className="float-left">
            <a href="javascript:void(0)" data-activates="slide-out" className="button-collapse"><i className="fa fa-bars" /></a>
          </div>
          {/* Breadcrumb */}
          <div className="breadcrumb-dn mr-auto">
            <p>Basic</p>
          </div>
          {/* Navbar links */}
          <ul className="nav navbar-nav nav-flex-icons ml-auto">
            {/* Dropdown */}
             <li className="nav-item">
                <Link className="nav-link waves-effect font-weight-bold" to="/signup">Sign Up</Link>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle waves-effect" href="#" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-user" /> <span className="clearfix d-none d-sm-inline-block">Profile</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                <Link className="dropdown-item" to="/dealer-dashboard/user-profile">Profile</Link>
                <Link className="dropdown-item" to="/dealer-dashboard/password-change">Password Change</Link>
                <Link className="dropdown-item" to="/dealer-dashboard/logout">Logout</Link>
                
              </div>
            </li>
          </ul>
          {/* Navbar links */}
        </nav>
        {/* Navbar */}
      </header>
    );
  }
}
export default SideBar;