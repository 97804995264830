import React from 'react';
import '../App.css';
import { Link,Route, Switch, Redirect } from 'react-router-dom';
import $ from "jquery";
import Spinner from './spiner';

class passwordchange extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
         item:null,
          isLoaded: false,
          adresss:null,
          phonenumer:null,
          password:null,
          cpassword:null,
          email:null,
          usernameid:null,
          adresss:null,
          access_token:null,
          message:null,


        };

      }
    componentDidMount (){
    
      var self=this;
       self.setState({ isLoaded: false });
       var access_token=localStorage.getItem('access_token');
       self.setState({access_token:access_token});
           var getStringPara={'access_token':access_token,'dealer_profile':'dealer_profile'};
          $.post(
                  "https://www.shopsmartautos.com/dealers_admin.php",
                  getStringPara,
                  function(data) {
                  var getResponse=JSON.parse(data);
                  
                  self.setState({item:getResponse.data});
                  self.setState({phonenumer:getResponse.data.phone});
                  self.setState({email:getResponse.data.dealeremail});
                  self.setState({usernameid:getResponse.data.usernameid});
                  self.setState({adresss:getResponse.data.Address});
                      self.setState({isLoaded:true});

                  }
                );

                }

           
            handleCpassword= cpassword=>{
                      var self=this;
                      cpassword.persist();
                      self.setState({cpassword:cpassword.target.value});

                    }

             handlePassword= GetPassword=>{
                      var self=this;
                      GetPassword.persist();
                      self.setState({password:GetPassword.target.value});

                    }
          
                    handleUser=handleUser=>{
                       var self=this;
                      handleUser.persist();
                      var cpassword=self.state.cpassword;
                      var password=self.state.password;
                      var access_token=self.state.access_token;
                      if(password!==cpassword)
                      {
                        self.setState({message:'<div class="alert alert-danger">Password doesnt Match</div>'})
                      }else
                      {
                      var getStringPara={'access_token':access_token,'dealer_password':'dealer_password','password':password,'cpassword':cpassword};
                      $.post(
                                "https://www.shopsmartautos.com/dealers_admin.php",
                                  getStringPara,
                                    function(data) {
                                    var getResponse=JSON.parse(data);
                                    self.setState({item:getResponse.data});
                                    if(getResponse.status==true)
                                      {
                                        self.setState({message:'<div class="alert alert-success">'+getResponse.message+'</div>'})
                                      }else
                                      {
                                          self.setState({message:'<div class="alert alert-danger">'+getResponse.message+'</div>'})
                                      }
                                    self.setState({isLoaded:true});

                              }
                            );
                    }
                    }
   render(){
    var access_token= localStorage.getItem('access_token');
 
 if (access_token===null)
 {
    return <Redirect to='/'/>
  }
var data=this.state.item;
var isLoaded=this.state.isLoaded;
   if (!isLoaded) {
          return <Spinner/>;
        } else {
    return (
          <main className="pt-5 mx-lg-5">
        <div className="col-lg-12 mt-5">
        {/* Card */}
        <div className="card card-cascade narrower">
          {/* Card image */}
          <div className="view view-cascade gradient-card-header mdb-colors ">
            <h5 className="mb-0 font-weight-bold">Password Change</h5>
          </div>
          {/* Card image */}
          {/* Card content */}
          <div className="card-body card-body-cascade text-center">
            {/* Edit Form */}
            
              {/* First row */}
              <div className="row">
                {/* First column */}
                {/* Second column */}
                <div className="col-md-6">
                  <div className="form-group mb-0 text-left">
                    <label htmlFor="form2" data-error="wrong" data-success="right"  className="p-0">Password</label>
                    <input type="text" id="form2" onChange={this.handlePassword} className="form-control validate"/>
                  
                  </div>
                </div>
              </div>
             
              <div className="row">
                {/* First column */}
                
                {/* Second column */}
                <div className="col-md-6 text-left">
                  <div className="form-group mb-0">
                  <label htmlFor="form77" className="p-0" data-error="wrong" data-success="right" >Confirm Password</label>
                    <input type="text" id="form77" onChange={this.handleCpassword}   className="form-control validate" />
                    
                  </div>
                </div>
              </div>
              {/* Fourth row */}
              <div className="row">
              
                <div className="col-md-12 text-center my-4">
                  <span className="waves-input-wrapper waves-effect waves-light"><button onClick={this.handleUser}  className="btn btn-primary btn-rounded" >Change Password</button></span>
                </div>
                <div dangerouslySetInnerHTML={{ __html: this.state.message}}/>
              </div>
              {/* Fourth row */}
          
            {/* Edit Form */}
          </div>
          {/* Card content */}
        </div>
        {/* Card */}
      </div></main>

    );
  }
}
}
export default passwordchange;