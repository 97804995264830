import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import $ from "jquery";
import SideBar from "./sidebar";
import Spinner from './spiner';

import Datatable from 'react-bs-datatable';
class subscriber extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
          header:null,
          body:null,
          loads:false,
          item:null,

        };
      }
    componentDidMount (){
      var self=this;
     localStorage.removeItem('access_token');
     localStorage.removeItem('dealerid');
     window.location.href='/';
     
}
   render(){
console.log(this.state.body);
   if(!this.state.loads)
   {

    return <Spinner/>;
   }
}
}
export default subscriber;