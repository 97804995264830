import React from 'react';
import $ from "jquery";
import '../App.css';
import { Link } from 'react-router-dom';

import Spinner from './spiner';

import 'bootstrap/dist/css/bootstrap.css';// main css file
import 'bootstrap-daterangepicker/daterangepicker.css'; // theme css file
import 'react-upload-gallery/dist/style.css'
import DateRangePicker from 'react-bootstrap-daterangepicker';

class reports extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
         item:null,
          isLoaded: true
        };
        

      }

           handleEvent(event, picker) {
    console.log(picker.startDate);
  }
  handleCallback(start, end, label) {
    console.log(start, end, label);
  }
componentDidMount(){
         
      }
    



   render(){

var isLoaded=this.state.isLoaded;

const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }
    var oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
var d = new Date();
var day = days[d.getDay()];
var hr = d.getHours();
var min = d.getMinutes();
if (min < 10) {
    min = "0" + min;
}
var ampm = "am";
if( hr > 12 ) {
    hr -= 12;
    ampm = "pm";
}
var date = d.getDate();
var dates = oneWeekAgo.getDate();
var month = months[d.getMonth()];
var months = months[oneWeekAgo.getMonth()];
var year = d.getFullYear();
var years = oneWeekAgo.getFullYear();
var x = document.getElementById("time");
var today=month + " " + date + ", " + year;
var todays=months + " " +  dates + ", " + years;

   if (!isLoaded) {
          return <Spinner/>;
        } else {
    return (
          <main className="pt-5 mx-lg-5">
        <div className="col-lg-12 mt-5">
        {/* Card */}
        <div className="row mb-0">
                {/* First column */}
                <div className="col-md-12 ">
                  {/* Panel content */}
                  <div className="card pt-0">
                    {/* Table */}
                    <div className="table-responsive ">
                      <table className="table table-hover">
                        {/* Table head */}
                        <thead className="rgba-stylish-strong white-text">
                          <tr>
                            <th>Report Type</th>
                            <th>Report Date</th>
                            <th>Report Date Range</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* Table body */}
                        <tbody>
                          <tr className="none-top-border">
                            <td>automotive_executive</td>
                            <td>Jun 01, 2020</td>
                            <td>May01,2020 - May 31, 2020</td>
                           
                          </tr>
                          
                        </tbody>
                        {/* Table body */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* Bottom Table UI */}
                    <div className="d-flex justify-content-between">
                      {/* Name */}
                      <select className="mdb-select colorful-select dropdown-primary mt-2">
                        <option value disabled>Rows number</option>
                        <option value={1} selected>5 rows</option>
                        <option value={2}>25 rows</option>
                        <option value={3}>50 rows</option>
                        <option value={4}>100 rows</option>
                      </select>
                    </div>
                    {/* Bottom Table UI */}
                  </div>
                  {/* Panel content */}
                  <div className="card">

                    <div className="card-body">
                      <h5>Generate Report</h5>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-3">
                          <label className="form-label">Report type</label>
                          </div>
                          <div className="col-lg-6">
                          <input type="text" className="form-control" disabled value="automotive_executive"/>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-lg-3">
                          <label className="form-label">Date Range</label>
                          </div>
                          <div className="col-lg-6">
                        <DateRangePicker onEvent={this.handleEvent} onCallback={this.handleCallback}  initialSettings={{ startDate: selectionRange.startDate, endDate: selectionRange.endDate }}>
                        <p className="dateInput"><span className="fa fa-calendar"></span> {todays} - {today} <span className="fa fa-angle-down" ></span></p>
                          </DateRangePicker>

                          </div>
                          </div>
                        </div>
                    
                    <div className="form-group">
                        <div className="row">
                          <div className="col-lg-3">
                          <label className="form-label">Email To</label>
                          </div>
                          <div className="col-lg-6">
                        <input type="text" className="form-control"/>
                          </div>
                          </div>
                        </div>
                    <div className="form-group">
                        <div className="row">
                          <div className="col-lg-3">
                          <label className="form-label">Email CC</label>
                          </div>
                          <div className="col-lg-6">
                        <input type="text" className="form-control" placeholder="Enter Emails seprate by comma"/>
                          </div>
                          </div>
                    </div>
                     <div className="form-group">
                        <div className="row">
                          <div className="col-lg-3">
                         </div>
                          <div className="col-lg-6">
                        <button className="btn btn-primary">Generate Report</button> </div>
                          </div>
                    </div>
                  </div>
                </div>
                <div className="card pt-0">
                    {/* Table */}
                    <div className="card-body">
                    <h5> Report History</h5>
                    </div>
                    <div className="table-responsive ">
                      <table className="table table-hover">
                        {/* Table head */}
                        <thead className="rgba-stylish-strong white-text">
                          <tr>
                            <th>REPORT DATE</th>
                            <th>REPORT NAME</th>
                            <th>REPORT URL</th>
                            <th>REPORT Date Range</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* Table body */}
                        <tbody>
                          <tr className="none-top-border">
                            <td>May 14, 2020</td>
                            <td><a href="#">Automotive Exicutive Summary Report: may</a></td>
                            <td><a href="/dealer-dashboard/report-view">https://report.shopsmartatutos.com</a></td>
                            <td>April 01, 2020 - April 30, 2020</td>
                            <td><a href="#">Delete</a></td>
                           
                          </tr>
                         </tbody>
                        {/* Table body */}
                      </table>
                  
                    </div>
                    {/* Table */}
                    {/* Bottom Table UI */}
                    <div className="d-flex justify-content-between">
                      {/* Name */}
                      <select className="mdb-select colorful-select dropdown-primary mt-2">
                        <option value disabled>Rows number</option>
                        <option value={1} selected>5 rows</option>
                        <option value={2}>25 rows</option>
                        <option value={3}>50 rows</option>
                        <option value={4}>100 rows</option>
                      </select>
                    </div>
                    {/* Bottom Table UI */}
                  </div>
                  
                {/* First column */}
                </div>
              </div>
        {/* Card */}
      </div></main>

    );
  }
}
}
export default reports;
