import React from 'react';
import $ from "jquery";
import '../App.css';

import '../custom.js';
import { Link } from 'react-router-dom';

import Spinner from './spiner';
import Map from './map';
import RUG from 'react-upload-gallery'
import {GoogleApiWrapper} from 'google-maps-react';

import 'react-upload-gallery/dist/style.css'
class adsadd extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
         item:null,
          isLoaded: true,
          adresss:null,
          phonenumer:null,
          email:null,
          usernameid:null,
          adresss:null,
          access_token:null,
          message:null,
          appendDivd:[],
          vehicleName:'New 2020 JeepCompass LATITUDE 4X4 Sport Utility',
          Milage:1000,
          images:[],
          PriceVehicle:null,
          inventorytypevalue:null,
          DealerName:null,
          DealerLongitude:null,
          DealerLatitude:null,
          Dealercountry:null,
          Dealerstate:null,
          Dealercity:null,
          Dealerwebsite:null,

        };
        this.humanize = this.humanize.bind(this)
        this.RemoveInput = this.RemoveInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
      }
    
            handlePhoneNumber= GetNumber=>{
                      var self=this;
                        GetNumber.persist();
                      self.setState({phonenumer:GetNumber.target.value});
                      }
                   humanize=str=> {
                        var i, frags = str.split('_');
                        for (i=0; i<frags.length; i++) {
                      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                        }
                           return frags.join(' ');
                     }
                    
            handleEmail= GetEmail=>{
                      var self=this;
                      GetEmail.persist();
                      self.setState({email:GetEmail.target.value});

                    }


                     handleDealerName= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({DealerName:GetName.target.value});

                    }
                      handlemogitude= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({DealerLongitude:GetName.target.value});

                    }
                    handlelatitude= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({DealerLatitude:GetName.target.value});

                    }
                     handleZipcode= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({Dealerzipcode:GetName.target.value});

                    }
                    handleCountry= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({Dealercountry:GetName.target.value});

                    }

                    handleState= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({Dealerstate:GetName.target.value});

                    } 
                     handleCity= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({Dealercity:GetName.target.value});

                    }

                     handleWebsite= GetName=>{
                      var self=this;
                      GetName.persist();
                      self.setState({Dealerwebsite:GetName.target.value});

                    } 


             handleUsername= GetPassword=>{
                      var self=this;
                      GetPassword.persist();
                      self.setState({usernameid:GetPassword.target.value});

                    }
            handleAddress= handleAddress=>{
                      var self=this;
                      handleAddress.persist();
                      self.setState({adresss:handleAddress.target.value});

                    }
                    addMore=inpu=>
                    {
                      var self=this;
                      var getArr=self.state.appendDivd;
                      getArr.push(1);
                     self.setState({appendDivd:getArr});
                      
                    }

                  RemoveInput=inpus=>
                    {
                      var self=this;
                        var arrget=self.state.appendDivd;
                      arrget.splice(inpus, 1);
                      self.setState({appendDivd:arrget})
                    }

                    handleNameVehicle=name=>
                    {
                      var self=this;
                     name.persist();
                      self.setState({vehicleName:name.target.value});
                    }

                     handleMilage=value=>
                    {
                      var self=this;
                     value.persist();
                      self.setState({Milage:value.target.value});
                    }
                     handlePrice=value=>
                    {
                      var self=this;
                     value.persist();
                      self.setState({PriceVehicle:value.target.value});
                    } 

                     inventorytype=value=>
                    {
                      var self=this;
                        value.persist();
                      self.setState({inventorytypevalue:value.target.value});
                    }

                    RemoveInputs=inpus=>
                    {
                      $('#id'+inpus).remove();
                      
                    }
                    handleVIN=handleVIN=>{
                       var self=this;
                      handleVIN.persist();
                    var getVin=handleVIN.target.value;
                    var getStringPara={};
                      if(getVin.length<=17)
                      {
                        self.setState({vin:getVin});
                        getStringPara['vinsearch']=getVin;
                        getStringPara['getvindata']='getvindata';
                         $.post(
                                "https://www.shopsmartautos.com/ads_user.php",
                                  getStringPara,
                                    function(data) {
                                    var getResponse=JSON.parse(data);
                                    self.setState({item:getResponse.data});

                                    if(getResponse.status==true)
                                      {

                                    delete getResponse.data.is_valid;
                                    delete getResponse.data.decode_mode;
                                    delete getResponse.data.status;
                                    delete getResponse.data.message;

                                    self.setState({keys:Object.keys(getResponse.data)});
                                    self.setState({values:Object.values(getResponse.data)});
                                    
                                   
                                        self.setState({message:'<span class="fa fa-check" style="display:inline" role="status" aria-hidden="true"></span><p style="color:green;display:inline;">'+getResponse.message+'</p>'});
                                      }else
                                      {
                                          self.setState({message:'<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span><p style="color:red">'+getResponse.message+'</p>'});
                                      }



                                    self.setState({isLoaded:true});

                              }
                            )
                      }else
                      {
                        console.log('sdasdasda');
                      }
                        
                      }
                     
           
                      handleVehicleProperty=getprop=>{
                        var self=this;
                        var getvData=self.state.item;
                        getprop.persist();
                       var getprops= getprop.target.getAttribute('property')
                       getvData[getprops]=getprop.target.value;
                        
                        self.setState({item:getvData})
                        
                        self.setState({keys:Object.keys(getvData)});
                        self.setState({values:Object.values(getvData)});
                                    

                      }
  // receives array of files that are done uploading when submit button is clicked
            handleSubmit = (files, allFiles) => {
            console.log(files.map(f => f.meta))
            allFiles.forEach(f => f.remove())
          }
            onConfirmDelete = (currentImage, images) => {
              $('#frameModalTopInfoDemo').show();
              $('#frameModalTopInfoDemo').toggleClass('show');
          }






   render(){

var data=this.state.item;
var isLoaded=this.state.isLoaded;
var keys=this.state.keys;
var slicedArray=keys !=null ? keys.slice(0, 5):('');
var values=this.state.values;
var self=this;

   if (!isLoaded) {
          return <Spinner/>;
        } else {
    return (
          <main className="pt-5 mx-lg-5">
        <div className="col-lg-12 mt-5">
        {/* Card */}
        <div className="card card-cascade narrower">
          {/* Card image */}
          <div className="view view-cascade gradient-card-header mdb-colors ">
            <h5 className="mb-0 font-weight-bold">Ads Add</h5>
          </div>
          {/* Card image */}
          {/* Card content */}
          <div className="card-body card-body-cascade text-center">
            <h2 className="text-center font-bold pt-4 pb-5 mb-5"><strong>Create ad </strong></h2>
        {/* Stepper */}
        <div className="steps-form-2">
          <div className="steps-row-2 setup-panel-2 d-flex justify-content-between">
            <div className="steps-step-2">
              <a href="#step-1" type="button" className="btn btn-amber btn-circle-2 waves-effect ml-0" data-toggle="tooltip" data-placement="top" title="Basic Information"><i className="fa fa-gear" aria-hidden="true" /></a>
            </div>
            <div className="steps-step-2">
              <a href="#step-2" type="button" className="btn btn-blue-grey btn-circle-2 waves-effect" data-toggle="tooltip" data-placement="top" title="Personal Data"><i className="fa fa-photo" aria-hidden="true" /></a>
            </div>
            <div className="steps-step-2">
              <a href="#step-3" type="button" className="btn btn-blue-grey btn-circle-2 waves-effect" data-toggle="tooltip" data-placement="top" title="Terms and Conditions"><i className="fa fa-user-circle-o" aria-hidden="true" /></a>
            </div>
            <div className="steps-step-2">
              <a href="#step-4" type="button" className="btn btn-blue-grey btn-circle-2 waves-effect mr-0" data-toggle="tooltip" data-placement="top" title="Finish"><i className="fa fa-check" aria-hidden="true" /></a>
            </div>
          </div>
        </div>
        {/* First Step */}
        <form role="form"  method="post">
          <div className="row setup-content-2" id="step-1">

            <div className="form-group md-form  col-lg-12">
             <h3 className="font-weight-bold pl-0 my-4"><strong>Technical Information</strong></h3>
           <div className="row">
           <div className="col-lg-6">

                 
                    <div className=" row">
                        <input id="VinNumber" type="text" onChange={this.handleVIN} required="required" className="" placeholder="Enter Vin Number"/>
                            <div dangerouslySetInnerHTML={{ __html:this.state.message}}/>
                              {keys!=null ? (<small>If vehicle data is not correct then add or edit the fields</small>):('')}
                    </div>
                    <div className="append_input_property">
                              {keys!=null ? keys.map((item,index)=>(
               
                        <div className="row length" id={'id'+index}> 
                              <div className=" "> 
                                  <input id="yourUsername-2"  t={index} value={this.humanize(item)} type="text" required="required" />
                              </div> 
                        <div > 
                              <input id="yourUsername-2"  property={item} onChange={this.handleVehicleProperty} type="text" value={values !=undefined  ? values[index] :('')} required="required"  />
                        </div>
                              <a className="btn-floating btn-sm btn-danger " onClick={() => this.RemoveInputs(index)}><i className="fas fa-minus"></i>
                              </a> 
                        </div> )):('')}
               </div>


               {this.state.appendDivd.map((value,index)=>(
                    <div className="row" key={index}> 
                      <div className="" > 
                      <input id="yourUsername-2"  type="text" required="required" placeholder="property"/>
                    </div> 
                     <div className=""> 
                    <input id="yourUsername-2"  type="text"  required="required"  placeholder="value" />
                    </div>
                    <a className="btn-floating btn-sm btn-danger " onClick={() => self.RemoveInput(index)}><i className="fas fa-minus"></i></a>
               </div>
                ))}
               <div className="row"> 
                      <div className=""> 
                      <input id="yourUsername-2"  type="text" required="required" placeholder="property"/>
                    </div> 
                     <div className=""> 
                    <input id="yourUsername-2"  type="text"  required="required"  placeholder="value" />
                    </div>
                    <a className="btn-floating btn-sm btn-warning" onClick={this.addMore}><i className="fas fa-plus"></i></a>
               </div>
               </div>

               <div className="col-lg-6">

                    <div className=" row">
                        <input id="VinNumber" type="text" onChange={this.handleNameVehicle}  required="required" className="form-control" placeholder="New 2020 JeepCompass LATITUDE 4X4 Sport Utility"/>

                    </div>
                    <div className=" row">
                        <input id="milage" type="text"  onChange={this.handleMilage} required="required" className="form-control" placeholder="Milage"/>
                        
                    </div>
                    <div className="row">
                        <input id="price" type="text"  required="required" onChange={this.handlePrice} className="form-control" placeholder="Price:USD"/>
                        
                    </div>
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="defaultUnchecked"/>
                        <label className="custom-control-label" for="defaultUnchecked">Kbb pricing?</label>
                      </div>
                    <div>
                  <div className="">
                 <p className="text-left mt-2"><strong>inventory type</strong></p>
                      <div className="form-group text-left">
                        <input type="radio" onChange={this.inventorytype} id="new" name="inventorytype" value="New" />
                        <span className="text-left" for="New" >New</span>
                    </div>
                    <div className="form-group text-left">
                        <input type="radio" onChange={this.inventorytype} id="used" name="inventorytype" value="Used"  />
                        <span className="text-left" for="used">Used</span>
                    </div>
                    <div className="form-group text-left">
                        <input type="radio" onChange={this.inventorytype} id="certified" name="inventorytype" value="Certified" />
                        <span className="text-left" for="certified">Certified</span>
                    </div>
                    </div>
                </div>
               </div>

               </div>
               </div>
              

              <button className="btn btn-mdb-color btn-rounded nextBtn-2 float-right" type="button">Next</button>
            </div>
      
          {/* Second Step */}
          <div className="row setup-content-2" id="step-2">
            <div className="col-md-12">
              <h3 className="font-weight-bold pl-0 my-4"><strong>Add Images</strong></h3>
          <RUG onChange={(images) => {this.setState({ images:images }) }} onConfirmDelete={this.onConfirmDelete}/>

              <button className="btn btn-mdb-color btn-rounded prevBtn-2 float-left" type="button">Previous</button>
              <button className="btn btn-mdb-color btn-rounded nextBtn-2 float-right" type="button">Next</button>
              <div className="modal fade top" id="frameModalTopInfoDemo" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-backdrop="false">
        <div className="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
          {/* Content */}
          <div className="modal-content">
            {/* Body */}
            <div className="modal-body">
              <div className="row d-flex justify-content-center align-items-center">
                <p className="pt-3 pr-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nisi quo
                  provident
                  fugiat reprehenderit nostrum quos..</p>
                <a type="button" className="btn btn-info">Get it now <i className="far fa-gem ml-1" /></a>
                <a type="button" className="btn btn-outline-info waves-effect" data-dismiss="modal">No, thanks</a>
              </div>
            </div>
          </div>
          {/* Content */}
        </div>
      </div>
            </div>
          </div>
          {/* Third Step */}
          <div className="row setup-content-2" id="step-3">
            <div className="col-md-12">
            

              <h3 className="font-weight-bold pl-0 my-4"><strong>Dealer Information</strong></h3>

               <div className="form-group md-form">
                <label htmlFor="yourName-2" data-error="wrong" data-success="right">Dealer Name</label>
                <input id="yourName-2" type="text" required="required" onChange={this.handleDealerName} className="form-control validate" />
              </div>
              <div className="form-group md-form mt-3">
                <label htmlFor="secondName-2" data-error="wrong" data-success="right">Website url</label>
                <input id="secondName-2" type="text" onChange={this.handleWebsite} required="required" className="form-control validate" />
              </div>
              <div className="form-group md-form">
                <label htmlFor="surname-2" data-error="wrong" data-success="right">City</label>
                <input id="surname-2" type="text" required="required" onChange={this.handleCity} className="form-control validate" />
              </div>
              <div className="form-group md-form">
                <label htmlFor="surname-2" data-error="wrong" data-success="right">State</label>
                <input id="surname-2" type="text" required="required" onChange={this.handleState} className="form-control validate" />
              </div>

                <div className="form-group md-form">
                <label htmlFor="surname-2" data-error="wrong" data-success="right">Country</label>
                <input id="surname-2" type="text" required="required" onChange={this.handleCountry} className="form-control validate" />
              </div>
               <div className="form-group md-form">
                <label htmlFor="surname-2" data-error="wrong" data-success="right">Zipcode</label>
                <input id="surname-2" type="text" required="required" onChange={this.handleZipcode} className="form-control validate" />
              </div>
              <div className="form-group md-form">
              <small>Choose the location under the map</small>
                <input id="lati" type="text" required="required" onChange={this.handlelatitude} className=" validate" placeholder="Latitude" />
                <input id="longi" type="text" required="required" onChange={this.handlemogitude} className=" validate" placeholder="Longitude"/>
              </div>
               <div  style={{width: '100%', height: '500px', position: 'relative'}}> <Map/></div> 
              <button className="btn btn-mdb-color btn-rounded prevBtn-2 float-left" type="button">Previous</button>
              <button className="btn btn-mdb-color btn-rounded nextBtn-2 float-right" type="button">Next</button>
            </div>
          </div>
          {/* Fourth Step */}
          <div className="row setup-content-2" id="step-4">
            <div className="col-md-12">
              <h3 className="font-weight-bold pl-0 my-4"><strong>Finish</strong></h3>
              <div className="car-box-3 row customrow">
   <div className="col-lg-4  col-results">
      <a target="_blank" href="/car-details/?id=1C6SRFFTXLN216245-7b6001f1-dfb7">
         <div className="badge-overlay"><span className="top-left badge oranges">{this.state.inventorytypevalue}</span></div>
         <img className="d-block w-100" src={this.state.images[0]!=null ? this.state.images[this.state.images.length-1].source :('')} alt="car" />
      </a>
      <ul className="social-icons-lists icons-background-square" style={{marginTop: '18px'}}>
      <li className="social-icons-list-item"><a target="_blank" href="https://twitter.com/intent/tweet?url=dev.shopsmartautos.com/car-details/?id=1C6SRFFTXLN216245-7b6001f1-dfb7" className="social-icon"><span className="fa fa-twitter" style={{padding: '10px', fontSize: '16px', backgroundColor: 'rgb(77, 167, 222)'}} /></a></li>
      <li className="social-icons-list-item"><a target="_blank" href="https://www.facebook.com/sharer.php?u=dev.shopsmartautos.com/car-details/?id=1C6SRFFTXLN216245-7b6001f1-dfb7" className="social-icon"><span className="fa fa-facebook" style={{padding: '10px', fontSize: '16px', backgroundColor: 'rgb(62, 91, 152)'}} /></a></li>
      <li className="social-icons-list-item"><a target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url=dev.shopsmartautos.com/car-details/?id=1C6SRFFTXLN216245-7b6001f1-dfb7" className="social-icon"><span className="fa fa-linkedin" style={{padding: '10px', fontSize: '16px', backgroundColor: 'rgb(0, 120, 185)'}} /></a></li>
      <li className="social-icons-list-item"><a target="_blank" href="https://pinterest.com/pin/create/button/?url=dev.shopsmartautos.com/car-details/?id=1C6SRFFTXLN216245-7b6001f1-dfb7" className="social-icon"><span className="fa fa-pinterest" style={{padding: '10px', fontSize: '16px', backgroundColor: 'rgb(224, 42, 32)'}} /></a></li>
      </ul>
   </div>
   <div className="col-lg-4 newclas card-body">
      <p className="vehicle-name"><span className="mini-hide">{this.state.vehicleName}</span></p>
      <p className="miles-style" style={{marginBottom: '0px'}}>{this.state.Milage} Miles </p>
      <ul className="descriptions">
      {slicedArray!='' ? slicedArray.map((item,index)=>(

               <li><strong> {this.humanize(item)}: </strong>{values !=undefined  ? values[index] :('')}</li>

                 )):('')}
         <li><p style={{fontSize: '11px', fontStyle: 'italic', fontWeight: 600, color: 'gray', lineHeight: '14px'}}><i className="fa fa-map-marker"> </i>Port Jeff Chrysler Jeep Dodge Ram Distance from you only <strong>1.95</strong> Miles</p></li>
      </ul>
   </div>
   <div className="col-lg-4">
      <div className="inventory-right card-body">
         <ul className="descriptions">
            <li><strong className="price_new">${this.state.PriceVehicle}</strong><small className="smalls">Listing price</small></li>
            <li>VIN # :{this.state.vin}</li>
            <li>Port Jefferson Station,NY</li>
            <li className="location-tag"> KBB.com Expert Ratings<img data-src="/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" className=" ls-is-cached lazyloaded" src="https://shopsmartautos.com/wp-content/uploads/kbb/logo_kbb_vert_60x110.png" style={{height: '70px'}} /></li>
            <li>Overall Rating</li>
            <li>
               <small>
                  <div><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star" /><i className="fa fa-star-half-o" /></div>
                  <strong>4.5/5</strong>
               </small>
            </li>
         </ul>
         <p><a target="_blank" className="btn btn-primary" href="/car-details/?id=1C6SRFFTXLN216245-7b6001f1-dfb7">More Details</a></p>
      </div>
   </div>
</div>
              <button className="btn btn-mdb-color btn-rounded prevBtn-2 float-left" type="button">Previous</button>
              <button className="btn btn-success btn-rounded float-right" type="submit">Submit</button>
            </div>
          </div>
        </form>
          </div>
          {/* Card content */}
        </div>
        {/* Card */}
      </div></main>

    );
  }
}
}
export default adsadd;
