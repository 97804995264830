import React from 'react';
import '../App.css';
import { Link , Route, Switch, Redirect } from 'react-router-dom';
import $ from "jquery";

import Spinner from './spiner';
class facebookads extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
          header:null,
          body:null,
          loads:false,
          options:null,
          total:null,
          dealerInfo:null,
          vehicleInfo:null,
          currentdealer:null,

        };
      }


      hanleClickname=(id,rowid)=>{
          var self=this;
          console.log(rowid);
        var access_token=localStorage.getItem('access_token');
        var dealerid=localStorage.getItem('dealerid');
        var item=self.state.item;
var getStringPara={'access_token':access_token,'dealer_inventorybyVin':'dealer_inventorybyVin','vin':id};
              $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              var getResponse=JSON.parse(data);
                self.setState({currentdealer:item[rowid]})
                self.setState({vehicleInfo:getResponse.data})
              })  

            }



        
      humanize(str) {
                var i, frags = str.split('_');
                for (i=0; i<frags.length; i++) {
                  frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
                }

    componentDidMount (){
      var self=this;
  var access_token=localStorage.getItem('access_token');
 var dealerid=localStorage.getItem('dealerid');
 var getStringPara={'access_token':access_token,'fetch_mc_leads':'fetch_mc_leads','dealer_id':dealerid};
                         $.post(
              "https://www.shopsmartautos.com/dealers_admin.php",
              getStringPara
              ,
              function(data) {
              
              var getResponse=JSON.parse(data);
               console.log(getResponse);
                self.setState({item:getResponse.data})
               
                var leads=getResponse.data;
                
self.setState({loads:true});
                }
              );
                         
}

   render(){
 var access_token= localStorage.getItem('access_token');
 if (access_token==null)
 {
    return <Redirect to='/'  />
  }
   if(!this.state.loads)
   {

    return <Spinner/>;
   }else   {
    var info=this.state.vehicleInfo;
    return (
            <div className="col-lg-12 p-0">
                 <div className="p-0 text-right">
                  <button className="btn btn-danger">Close</button>
                  <button className="btn btn-primary">Save</button>
                </div>
            <div className="row">
                <div className="col-lg-4">
                    <div className="card">
                          <div className="card-body">
                                <div className="panel"><strong>Ad Creative</strong></div>
                                    <form>
                                        <div className="form-group">
                                                <label className="p-0">Location</label>
                                               <select className="form-control">
                                                    <option></option>
                                                    <option >Facebook</option>
                                                    <option >Google ads</option>
                                                </select>
                                        </div>

                                        <div className="form-group">
                                              <label className="p-0">Campaign</label>
                                               <div className="form-group">
                                                      <input type="radio" checked name="Campaigncreate"/>
                                                      <label>Cretae New</label>
                                                </div>
                                                <div className="form-group">
                                                      <input type="radio" name="Campaigncreate"/>
                                                      <label>Use Existing</label>
                                               </div>
                                               <small><strong>Note:</strong> New Campaign will be created for each ad</small>
                                        </div>
                                         <div className="form-group">
                                                <label className="p-0">Status</label>
                                               <select className="form-control">
                                                    <option selected>Active</option>
                                                    <option >Deactivate</option>
                                                </select>
                                        </div>
                                         <div className="form-group">
                                                <label className="p-0">FB Page</label>
                                               <select className="form-control">
                                                    <option ></option>
                                                    <option >Deactivate</option>
                                                </select>
                                        </div>
                                         <div className="form-group">
                                                <label className="p-0">Ad Type</label>
                                               <select className="form-control">
                                                    <option selected>Promote post</option>
                                                    <option >Deactivate</option>
                                                </select>
                                        </div>

                                        <div className="form-group">
                                                <label className="p-0">Text</label>
                                               <textarea row="3" col="3"   className="form-control">
                                               </textarea>
                                        <small><strong>Valid Macros:</strong> @year, @make, @model, @price, @vin</small>
                                        </div>
                                        <div className="form-group">
                                                <label className="p-0">Headline</label>
                                               <textarea row="2" col="2"  className="form-control">
                                               </textarea>
                                        <small><strong>Valid Macros:</strong> @year, @make, @model, @price, @vin</small>
                                        </div>
                                        <div className="form-group">
                                                <label className="p-0">Description</label>
                                               <textarea row="2" col="2"  className="form-control">
                                               </textarea>
                                        <small><strong>Valid Macros:</strong> @year, @make, @model, @price, @vin</small>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                      <div className="col-lg-4">
                     <div className="card">
                          <div className="card-body">
                                <div className="panel"><strong>Budget</strong></div>
                                    <form>
                                        <div className="form-group">
                                                <label className="p-0">Bid</label>
                                                <div className="input-group-prepend">
                                                <span className="input-group-text"   id="basic-addon1">$</span>
                                                <input type="number" step="0.5" className="form-control currency_custom" value="0.5"/>
                                                </div>
                                                
                                        </div>

                                        <div className="form-group">
                                                <label className="p-0">Daily Budget</label>
                                                <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">$</span>
                                                <input type="number" className="form-control currency_custom" value="5"/>

                                                </div>
                                                  <small>Actual amount spent daily may vary</small>
                                        </div>

                                         <div className="form-group">
                                                <label className="p-0">Duration</label>
                                                <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">Days</span>
                                                <input type="Number" className="form-control currency_custom" value="30"/>
                                               
                                                </div>
                                                 <small>The ad will run untill vehicle is sold or days mentioned above, whichever is sooner</small>
                                        </div>
                                    </form>
                                </div>
                            </div>
                              <div className="card">
                          <div className="card-body">
                                <div className="panel"><strong>Promotion Rules</strong></div>
                                    <form>
                                        <div className="form-group">
                                          <label className="p-0">Filters(Required)</label>
                                            <div className="row">
                                                <div className="col-lg-6 mt-2">
                                                    <select className="form-control">
                                                          <option selected>AND</option>
                                                          <option>OR</option>
                                                    </select>                                                
                                                </div>
                                                <div className="col-lg-6">
                                                    <button className="btn btn-primary ">Filter <span className="fa fa-plus"></span></button>                                              
                                              </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">                                            
                                                            <select className="form-control">
                                                                  <option selected>Days active</option>
                                                                  <option>OR</option>
                                                            </select> 
                                                </div>
                                                <div className="col-lg-6">
                                                            <select className="form-control ">
                                                              <option selected>></option>
                                                              <option>!</option>
                                                              </select> 
                                                </div>
                                                <div className="col-lg-3">
                                                   
                                                            <input type="text" className="form-control" value="15"/> 
                                                </div>
                                             </div>

                                        </div>
                                        <div className=""><span>Estimated Number of ads: -  </span><span className="fa fa-refresh"></span></div>
                                        
                                        <div className="form-group">
                                                <label className="p-0">Grouping </label><span>(Optional)</span><br/>
                                                <small>Single Ad will be created for this group</small>
                                                <input type="text" className="form-control " value="" placeholder="Select some options"/>
                                                  <small>Actual amount spent daily may vary</small>
                                        </div>

                                         <div className="form-group">
                                                <label className="p-0">Text</label><span>(optional)</span><br/>
                                                <small>It will override the original Text</small>
                                        
                                               <textarea row="2" col="2"  className="form-control">
                                               </textarea>
                                        </div>
                                    </form>
                                </div>
                            </div>


                      </div>
                      <div className="col-lg-4">

                       <div className="card">
                          <div className="card-body">
                                <div className="panel"><strong>Audience</strong></div>
                                    <form>
                                        <div className="form-group">
                                                <label className="p-0">Countries:</label>
                                              <input type="text" placeholder="Search for a country" className="form-control" />
                                        </div>

                                        <div className="form-group">
                                              <div className="form-check form-group mt-4">
                                                    <input className="form-check-input" type="checkbox" id="checkbox2"/>
                                                    <label className="form-check-label" htmlFor="checkbox2">Geo Target</label>
                                                </div>
                                              </div>
                                         <div className="form-group">
                                                <label className="p-0">Age:</label>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                      <select className="form-control">
                                                        <option selected>18</option>
                                                        <option >18-</option>
                                                        <option >18+</option>
                                                      </select>
                                                    </div>
                                                    <div className="col-lg-6">
                                                      <select className="form-control">
                                                        <option selected>Max</option>
                                                        <option >Min</option>
                                                      </select>
                                                    </div>
                                                </div>

                                        </div>
                                         <div className="form-group">
                                                <label className="p-0">Gender:</label>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                      <select className="form-control">
                                                        <option selected>All</option>
                                                        <option >Male</option>
                                                        <option >Female</option>
                                                        <option >other</option>
                                                      </select>
                                                    </div>
                                                    <div className="col-lg-6">
                                                      <select className="form-control">
                                                        <option selected>Max</option>
                                                        <option >Min</option>
                                                      </select>
                                                    </div>
                                                </div>
                                            
                                        </div>
                                        <div className="form-group">
                                                <label className="p-0">Intrested In:</label>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                      <select className="form-control">
                                                        <option selected>All</option>
                                                        <option >Men</option>
                                                        <option >Women</option>
                                                      </select>
                                                    </div>
                                                </div>     
                                        </div>
                                        <div className="form-group">
                                                <label className="p-0">Relationship Status:</label>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                      <select className="form-control">
                                                        <option selected>Please Select</option>
                                                        <option >Single</option>
                                                        <option >Married</option>
                                                        <option >Devorce</option>
                                                      </select>
                                                    </div>
                                                </div>
                                        </div>
                                        <div className="form-group">
                                                <label className="p-0">Education Status:</label>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                      <select className="form-control">
                                                        <option selected>All</option>
                                                        <option >Grauate</option>
                                                        <option >Post Grauate</option>
                                                      </select>
                                                    </div>
                                                </div>
                                        </div>
                                         
                                    </form>
                                </div>
                            </div>
                      </div>
                  </div>
                  </div>
        );
  }
}}

export default facebookads;