import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import $ from "jquery";
import Spinner from './spiner';

class userprofile extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
         item:null,
          isLoaded: false,
          adresss:null,
          phonenumer:null,
          email:null,
          usernameid:null,
          adresss:null,
          access_token:null,
          message:null,


        };
      }
    componentDidMount (){
    
      var self=this;
       self.setState({ isLoaded: false });
       var access_token=localStorage.getItem('access_token');
       self.setState({access_token:access_token});
           var getStringPara={'access_token':access_token,'dealer_profile':'dealer_profile'};
          $.post(
                  "https://www.shopsmartautos.com/dealers_admin.php",
                  getStringPara,
                  function(data) {
                  var getResponse=JSON.parse(data);
                  
                  self.setState({item:getResponse.data});
                  self.setState({phonenumer:getResponse.data.phone});
                  self.setState({email:getResponse.data.dealeremail});
                  self.setState({usernameid:getResponse.data.usernameid});
                  self.setState({adresss:getResponse.data.Address});
                      self.setState({isLoaded:true});

                  }
                );

                }

            handlePhoneNumber= GetNumber=>{
                      var self=this;
                        GetNumber.persist();
                      self.setState({phonenumer:GetNumber.target.value});
                      

                    }
            handleEmail= GetEmail=>{
                      var self=this;
                      GetEmail.persist();
                      self.setState({email:GetEmail.target.value});

                    }

             handleUsername= GetPassword=>{
                      var self=this;
                      GetPassword.persist();
                      self.setState({usernameid:GetPassword.target.value});

                    }
            handleAddress= handleAddress=>{
                      var self=this;
                      handleAddress.persist();
                      self.setState({adresss:handleAddress.target.value});

                    }
                    handleUser=handleUser=>{
                       var self=this;
                      handleUser.persist();
                      var getEmail=self.state.email;
                      var getPhoneNumber=self.state.phonenumer;
                      var access_token=self.state.access_token;
                      var usernameid=self.state.usernameid;
                      var adresss=self.state.adresss;
                         var getStringPara={'access_token':access_token,'dealer_update':'dealer_update','email':getEmail,'phonenumber':getPhoneNumber,'usernameid':usernameid,'adresss':adresss};
                      $.post(
                                "https://www.shopsmartautos.com/dealers_admin.php",
                                  getStringPara,
                                    function(data) {
                                    var getResponse=JSON.parse(data);
                                    self.setState({item:getResponse.data});
                                    if(getResponse.status==true)
                                      {
                                        self.setState({message:'<div class="alert alert-success">'+getResponse.message+'</div>'})
                                      }else
                                      {
                                          self.setState({message:'<div class="alert alert-danger">'+getResponse.message+'</div>'})
                                      }



                                    self.setState({isLoaded:true});

                              }
                            );
                    }
   render(){
var data=this.state.item;
var isLoaded=this.state.isLoaded;
   if (!isLoaded) {
          return <Spinner/>;
        } else {
    return (
          <main className="pt-5 mx-lg-5">
        <div className="col-lg-12 mt-5">
        {/* Card */}
        <div className="card card-cascade narrower">
          {/* Card image */}
          <div className="view view-cascade gradient-card-header mdb-colors ">
            <h5 className="mb-0 font-weight-bold">Edit Account</h5>
          </div>
          {/* Card image */}
          {/* Card content */}
          <div className="card-body card-body-cascade text-center">
            {/* Edit Form */}
            
              {/* First row */}
              <div className="row">
                {/* First column */}
                {/* Second column */}
                <div className="col-md-6">
                  <div className="form-group mb-0 text-left">
                    <label htmlFor="form2" data-error="wrong" data-success="right"  className="p-0">DEALER ID</label>
                    <input type="text" id="form2" onChange={this.handleUsername} defaultValue={data.DealerID} className="form-control validate" disabled/>
                  
                  </div>
                </div>
                <div className="col-md-6 text-left">
                  <div className="form-group mb-0">
                  <label className="left" htmlFor="form76" className="p-0">EMAIL ADDRESS</label>
                    <input type="email" id="form76" onChange={this.handleEmail} defaultValue={data.dealeremail} className="form-control validate" />
                    
                  </div>
                </div>
              </div>
             
              <div className="row">
                {/* First column */}
                
                {/* Second column */}
                <div className="col-md-6 text-left">
                  <div className="form-group mb-0">
                  <label htmlFor="form77" className="p-0" data-error="wrong" data-success="right" >Phone Number</label>
                    <input type="text" id="form77" onChange={this.handlePhoneNumber} defaultValue={data.phone}  className="form-control validate" />
                    
                  </div>
                </div>
              </div>
              {/* Second row */}
              {/* Third row */}
              <div className="row">
                {/* First column */}
                <div className="col-md-12">
                  <div className="form-group mb-0 text-left">
                    <label className="p-0" >Address</label>
                    <textarea type="text"  onChange={this.handleAddress} defaultValue={data.Address} className="md-textarea form-control" rows={3}  />

                  </div>
                </div>
              </div>
              {/* Third row */}
              {/* Fourth row */}
              <div className="row">
              <div dangerouslySetInnerHTML={{ __html:this.state.message}}/>
                <div className="col-md-12 text-center my-4">
                  <span className="waves-input-wrapper waves-effect waves-light"><button onClick={this.handleUser}  className="btn btn-primary btn-rounded" >Update Profile</button></span>
                </div>
              </div>
              {/* Fourth row */}
          
            {/* Edit Form */}
          </div>
          {/* Card content */}
        </div>
        {/* Card */}
      </div></main>

    );
  }
}
}
export default userprofile;