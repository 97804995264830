import React from 'react';
import '../App.css';
import $ from 'jquery';

import { Link } from 'react-router-dom';

class registerforads extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
          error: null,
          errors: [],
          isLoaded: false,
          items: [],
          phonenumer:null,
          email:null,
          password:null,
          message:null,
          Validationpnumer:null,
          varificationcode:null,
          access_token:null,
          userid:null,
        };
        this.FormValidations = this.FormValidations.bind(this)
      }

      FormValidationsVarificationCode=getValidate=>{
        var self=this;
        let varificationcode=self.state.varificationcode;
        let Messages={};
        if(varificationcode==null)
        {
          Messages['vcode']='Please fill up the Varification Code';       
          self.setState({errors:Messages});
          return false;
        }else
        {
          self.setState({errors:''});
          return true;
        }

        
      }
       FormValidations=getValidate=>{
        var self=this;
        let phonenumer=self.state.phonenumer;
        let email=self.state.email;
        let password=self.state.password;
        let Messages={};
        if(phonenumer!=null )
        {
          
          if(phonenumer.length<12)
          {
             Messages['phonenumer']='Please enter the valid Phone Number';
             self.setState({errors:Messages});
             return false;
          }
          
        }
        if(phonenumer==null)
        {
          Messages['phonenumer']='Please fill up the Phone Number';       
          self.setState({errors:Messages});
          return false;
        }else if(email==null)
        {
          Messages['email']='Please fill the Email';
          self.setState({errors:Messages});
          return false;
        }else if(password==null)
        {
          Messages['password']='Please fill up the Password!';
          self.setState({errors:Messages});
          return false;
        }else
        {
          self.setState({errors:''});
          return true;
        }

        
      }

      handleRegister=handleRegisterGet=>{
          handleRegisterGet.persist();
          var self=this;
          let phonenumer=self.state.phonenumer;
          let email=self.state.email;
          let password=self.state.password;

          if(self.FormValidations())
          {
          self.setState({ isLoaded: false });
          var getStringPara={'phonenumer':phonenumer,'email':email,'password':password,'authonticate':'authonticate'};
              $.post(
              "https://www.shopsmartautos.com/ads_user.php",
              getStringPara
              ,
              function(data) {
                $('.mobile_number_Varification').show();
                $('.hide').hide();
                $('.arrow_left').show();
                 $('.arrow_right').hide();
              var getResponse=JSON.parse(data);
                self.setState({userid:getResponse.code});
              if(getResponse.status==true)
              {
                self.setState({message:'<div class="alert alert-success">'+getResponse.message+'</div>'})
              }else
              {
                self.setState({message:'<div class="alert alert-danger">'+getResponse.message+'</div>'})
              }
              }
              ); 
          }

        }
          handleRegisterVarificationcode=getcode=>{
          getcode.persist();
          var self=this;
          let varificationcode=self.state.varificationcode;
         

          if(self.FormValidationsVarificationCode())
          {
          self.setState({ isLoaded: false });
          var getStringPara={'code':varificationcode,'Userid':self.state.userid,'varification_code':'varification_code'};
              $.post(
              "https://www.shopsmartautos.com/ads_user.php",
              getStringPara
              ,
              function(data) {
                
              var getResponse=JSON.parse(data);

              if(getResponse.status==true)
              {     
                    self.setState({access_token:getResponse.access_token})
                    localStorage.setItem('access_token',getResponse.access_token);
                self.setState({message:'<div class="alert alert-success">'+getResponse.message+'</div>'})
                window.location.href='/user-dashboard';
              }else
              {
                self.setState({message:'<div class="alert alert-danger">'+getResponse.message+'</div>'})
              }
              }
              ); 
          }

        }


            handlePhoneNumber= GetNumber=>{
                      var self=this;
                        GetNumber.persist();
                      self.setState({phonenumer:GetNumber.target.value});
                      

                    }
            handleEmail= GetEmail=>{
                      var self=this;
                      GetEmail.persist();
                      self.setState({email:GetEmail.target.value});

                    }

             handlePassword= GetPassword=>{
                      var self=this;
                      GetPassword.persist();
                      self.setState({password:GetPassword.target.value});

                    }
            handleVarificationCode= handleVarificationCode=>{
                      var self=this;
                      handleVarificationCode.persist();
                      self.setState({varificationcode:handleVarificationCode.target.value});

                    }
             handleFormChangeSignup= FormChange=>{
                      var self=this;
                      FormChange.persist();
                     $('.hide').show();
                     $('.arrow_left').hide();
                     $('.arrow_right').show();
                     $('.mobile_number_Varification').hide();

                    }
             handleFormChangeVarification= FormChangevarification=>{
                      var self=this;
                      FormChangevarification.persist();
                     $('.hide').hide();
                     $('.arrow_right').hide();
                      $('.arrow_left').show();
                     $('.mobile_number_Varification').show();

                    }

   render(){  
    return (
      <div className="col-lg-12 backgroundImage">
         {/* Default form login */}
	<div className=" row text-center ">
	<div className="col-lg-8">
	<div className="content text-white mt-5">
	
     <h1 className="content text-white mt-5">Welcome to ads program</h1>
   <p>ShopSmartAutos.com prides itself on offering an unprecedented inventory of over 6 million vehicles pulled from the top automotive vehicle sales sites and dealers across the country. Punch in the car you are looking for and see how many offers there are on that vehicle and where they are within driving distance from your home, or delivered to your doorstep.
		</p>
		</div>
        </div>
        <div className="col-lg-4  card text-center border border-light p-5 mt-5">
        <div className="arrows">
          <span className="arrow_left" onClick={this.handleFormChangeSignup}><i className="fa fa-long-arrow-left" aria-hidden="true"></i></span>
          <span className="arrow_right" onClick={this.handleFormChangeVarification}><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span>
        </div>
        <div className="hide">
        <p className="h4 mb-4">Sign up</p>
        {/* Email */}
        <input type="number" onChange={this.handlePhoneNumber} name="phonenumer" id="defaultLoginFor" className="form-control mb-4" placeholder="+1(908)-(229)-(2222)" />
       
       <p style={{color:"red"}}>{this.state.errors['phonenumer']}</p>
       
        <input type="email" name="email" id="defaultLoginFormEmail" onChange={this.handleEmail} className="form-control mb-4" placeholder="E-mail" />
        <p style={{color:"red"}}>{this.state.errors['email']}</p>
        
        <input type="password" name="password" id="defaultLoginFormPassword" onChange={this.handlePassword} className="form-control mb-4" placeholder="Password" />
        
        <p style={{color:"red"}}>{this.state.errors['password']}</p>
        
        <div className="d-flex justify-content-around">
          <div>
            {/* Forgot password */}
            <a href="true">Forgot password?</a>
          </div>
        </div>

        {/* Sign in button */}
        <div dangerouslySetInnerHTML={{ __html:this.state.message}}/>
        <button className="btn btn-primary btn-block my-4" onClick={this.handleRegister}>Sign up</button>
        {/* Register */}
        <p>Are you  member?
          <Link to="/">Login</Link>
        </p>
        {/* Social login */}

        </div>
        <div className="mobile_number_Varification">


        <p className="h4 mb-4">Varify Mobile Number</p>
        {/* Email */}
        <label>Enter Your Varification Code</label>
        <input type="number" onChange={this.handleVarificationCode} name="phonenumer" id="defaultLoginFor" className="form-control mb-4" placeholder="Six digit varification code" />
       
       <p style={{color:"red"}}>{this.state.errors['vcode']}</p>
        {/* Sign in button */}
        <div dangerouslySetInnerHTML={{ __html:this.state.message}}/>
        <button className="btn btn-primary btn-block my-4" onClick={this.handleRegisterVarificationcode}>Varify code</button>
        {/* Register */}
       
        {/* Social login */}

        </div>
        </div>


        </div>
        <div className=" text-center text-white"><h3 className="copyr">&copy; ShopSmartAutos 2020</h3></div>
        </div>
      

    );
  }
}
export default registerforads;