import React from 'react';
import '../App.css';

import { Link } from 'react-router-dom';
import $ from "jquery";
import Spinner from './spiner';
var LineChart = require("react-chartjs").Line;

class overview extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
         
         item:null,
          isLoaded: false,
          adresss:null,
          phonenumer:null,
          email:null,
          usernameid:null,
          adresss:null,
          access_token:null,
          message:null,


        };
      }
    componentDidMount (){
        var self=this;
    
       var access_token=localStorage.getItem('access_token');
       self.setState({access_token:access_token});
           var getStringPara={'access_token':access_token,'dealer_profile':'dealer_profile'};
          $.post(
                  "https://www.shopsmartautos.com/dealers_admin.php",
                  getStringPara,
                  function(data) {
                  var getResponse=JSON.parse(data);
                  self.setState({item:getResponse.data});
                  self.setState({phonenumer:getResponse.data.phonenumber});
                  self.setState({dealerid:getResponse.data.DealerID});
                  self.setState({email:getResponse.data.email});
                  self.setState({usernameid:getResponse.data.usernameid});
                  self.setState({adresss:getResponse.data.address});
                      self.setState({isLoaded:true});

                  }
                );

                }

            handlePhoneNumber= GetNumber=>{
                      var self=this;
                        GetNumber.persist();
                      self.setState({phonenumer:GetNumber.target.value});
                      

                    }
            handleEmail= GetEmail=>{
                      var self=this;
                      GetEmail.persist();
                      self.setState({email:GetEmail.target.value});

                    }

             handleUsername= GetPassword=>{
                      var self=this;
                      GetPassword.persist();
                      self.setState({usernameid:GetPassword.target.value});

                    }
            handleAddress= handleAddress=>{
                      var self=this;
                      handleAddress.persist();
                      self.setState({adresss:handleAddress.target.value});

                    }
                    handleUser=handleUser=>{
                       var self=this;
                      handleUser.persist();
                      var getEmail=self.state.email;
                      var getPhoneNumber=self.state.phonenumer;
                      var access_token=self.state.access_token;
                      var usernameid=self.state.usernameid;
                      var adresss=self.state.adresss;
                         var getStringPara={'access_token':access_token,'user_update':'user_update','email':getEmail,'phonenumber':getPhoneNumber,'usernameid':usernameid,'adresss':adresss};
                      $.post(
                                "https://www.shopsmartautos.com/ads_user.php",
                                  getStringPara,
                                    function(data) {
                                    var getResponse=JSON.parse(data);
                                    self.setState({item:getResponse.data});
                                    if(getResponse.status==true)
                                      {
                                        self.setState({message:'<div class="alert alert-success">'+getResponse.message+'</div>'})
                                      }else
                                      {
                                          self.setState({message:'<div class="alert alert-danger">'+getResponse.message+'</div>'})
                                      }



                                    self.setState({isLoaded:true});

                              }
                            );
                    }
   render(){
var data=this.state.item;
var isLoaded=this.state.isLoaded;




   if (!isLoaded) {
          return <Spinner/>;
        } else {

    return (
          <main className="pt-5 mx-lg-5 mt-5">
        <section className="mt-md-4 pt-md-2 mb-5 pb-4">
        {/* Grid row */}
        <div className="row">
          {/* Grid column */}
          <div className="col-xl-3 col-md-6 mb-xl-0 mb-4">
            {/* Card */}
            <div className="card card-cascade cascading-admin-card">
              {/* Card Data */}
              <div className="admin-up">
                <i className="far fa fa-car primary-color mr-3 z-depth-2" />
                <div className="data">
                  <p className="text-uppercase">Inventory</p>
                  <h4 className="font-weight-bold dark-grey-text">2000</h4>
                </div>
              </div>              
              {/* Card content */}
              
              <div className="card-body card-body-cascade">
                <div className="progress mb-3">
                  <div className="progress-bar bg-primary" role="progressbar" style={{width: '25%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                </div>
                <p className="card-text">Better than last week (25%)</p>
              </div>
            </div>
            {/* Card */}
          </div>
          {/* Grid column */}
          {/* Grid column */}
          <div className="col-xl-3 col-md-6 mb-xl-0 mb-4">
            {/* Card */}
            <div className="card card-cascade cascading-admin-card">
              {/* Card Data */}
              <div className="admin-up">
                <i className="fas fa-chart-line warning-color mr-3 z-depth-2" />
                <div className="data">
                  <p className="text-uppercase">Leads</p>
                  <h4 className="font-weight-bold dark-grey-text">200k</h4>
                </div>
              </div>
              {/* Card content */}
              <div className="card-body card-body-cascade">
                <div className="progress mb-3">
                  <div className="progress-bar red accent-2" role="progressbar" style={{width: '25%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                </div>
                <p className="card-text">Worse than last week (25%)</p>
              </div>
            </div>
            {/* Card */}
          </div>
          {/* Grid column */}
          {/* Grid column */}
          <div className="col-xl-3 col-md-6 mb-md-0 mb-4">
            {/* Card */}
            <div className="card card-cascade cascading-admin-card">
              {/* Card Data */}
              <div className="admin-up">
                <i className="fas fa-chart-pie light-blue lighten-1 mr-3 z-depth-2" />
                <div className="data">
                  <p className="text-uppercase">Sales</p>
                  <h4 className="font-weight-bold dark-grey-text">20.9k</h4>
                </div>
              </div>
              {/* Card content */}
              <div className="card-body card-body-cascade">
                <div className="progress mb-3">
                  <div className="progress-bar red accent-2" role="progressbar" style={{width: '75%'}} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                </div>
                <p className="card-text">Worse than last week (75%)</p>
              </div>
            </div>
            {/* Card */}
          </div>
          {/* Grid column */}
          {/* Grid column */}
          <div className="col-xl-3 col-md-6 mb-0">
            {/* Card */}
            <div className="card card-cascade cascading-admin-card">
              {/* Card Data */}
              <div className="admin-up">
                <i className="far fa-money-bill-alt red accent-2 mr-3 z-depth-2" />
                <div className="data">
                  <p className="text-uppercase"> Revenue</p>
                  <h4 className="font-weight-bold dark-grey-text">1M</h4>
                </div>
              </div>
              {/* Card content */}
              <div className="card-body card-body-cascade">
                <div className="progress mb-3">
                  <div className="progress-bar bg-primary" role="progressbar" style={{width: '25%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                </div>
                <p className="card-text">Better than last week (25%)</p>
              </div>
            </div>
            {/* Card */}
          </div>
          {/* Grid column */}
        </div>
        {/* Grid row */}
      </section>
 <div className="container-fluid">
        {/* Heading & Date */}
        
        {/* Heading & Date */}
        {/* Section: Main chart */}
        <section>
          {/* Card */}
          <div className="card card-cascade narrower">
            {/* Card image */}
            <div className="view view-cascade gradient-card-header blue-gradient">
            <LineChart data={[
          {
              value: 6,
              color:"#F7464A",
              highlight: "#FF5A5E",
              label: "Red"
          },
          {
              value: 6,
              color: "#46BFBD",
              highlight: "#5AD3D1",
              label: "Green"
          },
          {
              value: 6,
              color: "#FDB45C",
              highlight: "#FFC870",
              label: "Yellow"
          },
          {
              value: 6,
              color: "#949FB1",
              highlight: "#A8B3C5",
              label: "Grey"
          },
          {
              value: 6,
              color: "#4D5360",
              highlight: "#616774",
              label: "Dark Grey"
          }
          ]} options={{
            labels: ["Eating", "Drinking", "Sleeping", "Designing", "Coding", "Cycling", "Running"],
            datasets: [
                {
                    label: "My First dataset",
                    fillColor: "rgba(220,220,220,0.2)",
                    strokeColor: "rgba(220,220,220,1)",
                    pointColor: "rgba(220,220,220,1)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: 6
                },
                {
                    label: "My Second dataset",
                    fillColor: "rgba(151,187,205,0.2)",
                    strokeColor: "rgba(151,187,205,1)",
                    pointColor: "rgba(151,187,205,1)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(151,187,205,1)",
                    data: 6
                }
            ]
          }} width="600" height="250"/>
            </div>
            {/* Card image */}
            {/* Card content */}
            <div className="card-body card-body-cascade blue-panel text-center">
              {/* Second row */}
              <div className="row mx-3 mb-0 text-center">
                {/* Grid column */}
                <div className="col mt-1 mb-2">
                  <div className="btn-group mt-1" data-toggle="buttons">
                    <label className="btn btn-info btn-md active">
                      <input type="checkbox" name="options" id="option1" autoComplete="off" defaultChecked />Clicks <strong>1000</strong>
                      <i className="fas fa-arrow-up ml-2 white-text" /> <strong> 25%</strong>
                    </label>
                  </div>
                  <div className="btn-group mt-1" data-toggle="buttons">
                    <label className="btn btn-info btn-md">
                      <input type="checkbox" name="options" id="option2" autoComplete="off" />Impressions <strong>1000</strong>
                      <i className="fas fa-arrow-up ml-2 white-text" /> <strong> 25%</strong>
                    </label>
                  </div>
                  <div className="btn-group mt-1" data-toggle="buttons">
                    <label className="btn red accent-2 btn-md text-white">
                      <input type="checkbox" name="options" id="option2" autoComplete="off" />CTR <strong>5%</strong> <i className="fas fa-arrow-down ml-2 white-text" /> <strong> 25%</strong>
                    </label>
                  </div>
                  <div className="btn-group mt-1" data-toggle="buttons">
                    <label className="btn red accent-2 btn-md text-white">
                      <input type="checkbox" name="options" id="option2" autoComplete="off" />Position <strong>4.14</strong>
                      <i className="fas fa-arrow-down ml-2 white-text" /> <strong> 25%</strong>
                    </label>
                  </div>
                </div>
                {/* Grid column */}
              </div>
              {/* Second row */}
              {/* Third row */}
              <div className="row mx-3 mb-5 text-center">
                {/* First column */}
                <div className="col">
                 
                 
                </div>
                {/* Second column */}
              </div>
              {/* Third row */}
              {/* Third row */}
              <div className="row mb-0">
                {/* First column */}
                <div className="col-md-12">
                  {/* Panel content */}
                  <div className="card-body pt-0">
                    {/* Table */}
                    <div className="table-responsive">
                      <table className="table table-hover">
                        {/* Table head */}
                        <thead className="rgba-stylish-strong white-text">
                          <tr>
                            <th>Queries</th>
                            <th>Clicks</th>
                            <th>Impressions</th>
                            <th>CTR</th>
                            <th>Position</th>
                            <th>Day</th>
                            <th>Week</th>
                            <th>Month</th>
                            <th>Local</th>
                            <th>Global</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* Table body */}
                        <tbody>
                          <tr className="none-top-border">
                            <td>bootstrap material design</td>
                            <td>1000</td>
                            <td>10000</td>
                            <td>5%</td>
                            <td>3.21</td>
                            <td>2 <span className="badge primary-color">1 <i className="fas fa-arrow-circle-up" /></span></td>
                            <td>2 <span className="badge red accent-2">1 <i className="fas fa-arrow-circle-down" /></span></td>
                            <td>2 <span className="badge grey">0 </span></td>
                            <td>200 (US)</td>
                            <td>2000</td>
                          </tr>
                          <tr>
                            <td>bootstrap material design</td>
                            <td>1000</td>
                            <td>10000</td>
                            <td>5%</td>
                            <td>3.21</td>
                            <td>2 <span className="badge primary-color">1 <i className="fas fa-arrow-circle-up" /></span></td>
                            <td>2 <span className="badge red accent-2">1 <i className="fas fa-arrow-circle-down" /></span></td>
                            <td>2 <span className="badge grey">0 </span></td>
                            <td>200 (US)</td>
                            <td>2000</td>
                          </tr>
                          <tr>
                            <td>bootstrap material design</td>
                            <td>1000</td>
                            <td>10000</td>
                            <td>5%</td>
                            <td>3.21</td>
                            <td>2 <span className="badge primary-color">1 <i className="fas fa-arrow-circle-up" /></span></td>
                            <td>2 <span className="badge red accent-2">1 <i className="fas fa-arrow-circle-down" /></span></td>
                            <td>2 <span className="badge grey">0 </span></td>
                            <td>200 (US)</td>
                            <td>2000</td>
                          </tr>
                        </tbody>
                        {/* Table body */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* Bottom Table UI */}
                    <div className="d-flex justify-content-between">
                      {/* Name */}
                      <select className="mdb-select colorful-select dropdown-primary mt-2">
                        <option value disabled>Rows number</option>
                        <option value={1} selected>5 rows</option>
                        <option value={2}>25 rows</option>
                        <option value={3}>50 rows</option>
                        <option value={4}>100 rows</option>
                      </select>
                      {/* Pagination */}
                      <nav className="my-4">
                        <ul className="pagination pagination-circle pg-blue mb-0">
                          {/* First */}
                          <li className="page-item disabled clearfix d-none d-md-block"><a className="page-link">First</a></li>
                          {/* Arrow left */}
                          <li className="page-item disabled">
                            <a className="page-link" aria-label="Previous">
                              <span aria-hidden="true">«</span>
                              <span className="sr-only">Previous</span>
                            </a>
                          </li>
                          {/* Numbers */}
                          <li className="page-item active"><a className="page-link">1</a></li>
                          <li className="page-item"><a className="page-link">2</a></li>
                          <li className="page-item"><a className="page-link">3</a></li>
                          <li className="page-item"><a className="page-link">4</a></li>
                          <li className="page-item"><a className="page-link">5</a></li>
                          {/* Arrow right */}
                          <li className="page-item">
                            <a className="page-link" aria-label="Next">
                              <span aria-hidden="true">»</span>
                              <span className="sr-only">Next</span>
                            </a>
                          </li>
                          {/* First */}
                          <li className="page-item clearfix d-none d-md-block"><a className="page-link">Last</a></li>
                        </ul>
                      </nav>
                      {/* Pagination */}
                    </div>
                    {/* Bottom Table UI */}
                  </div>
                  {/* Panel content */}
                </div>
                {/* First column */}
              </div>
              {/* Third row */}
            </div>
            {/* Card content */}
          </div>
          {/* Card */}
        </section>
        {/* Section: Main chart */}
      </div>
      </main>

    );
  }
}
}
export default overview;